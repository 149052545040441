/* eslint-disable */

import {level2} from "./nimaRectangle";

const MS = {
    thickness: 4,
    headerH: 20
}

export class NimaFrame extends level2 {
    constructor(e, t, n, o, a, r, i, s) {
        super(),
            this.x = e,
            this.y = t,
            this.w = n,
            this.h = o,
            this.color = r,
            this.title = a,
            this.children = s,
            this.order = i,
            this.cmd = "frame"
    }
    set title(e) {
        this._cached = {},
            this._title = e
    }
    get title() {
        return this._title
    }
    isFixedPosition() {
        return !0
    }
    getAspectRatio(e) {
        return "boolean" == typeof e && e ? "preserved" : "free"
    }
    getThickness() {
        return MS.thickness / 2
    }
    updateSize(e, t, n, o) {
        this.updatePosition(e, t),
            this.w = n - MS.thickness,
            this.h = o - MS.thickness
    }
    updatePosition(e, t) {
        this.x = e + this.getThickness(),
            this.y = t + this.getThickness()
    }
    getBoundingRect(e) {
        var t = e && e.includeOuter ? MS.headerH * (this.boardContext ? this.boardContext.scale : 1) : 0
            , n = this.getThickness();
        return {
            x: this.x - n,
            y: this.y - n - t,
            w: this.w + MS.thickness,
            h: this.h + MS.thickness
        }
    }
    mostlyOverlapped(e) {
        var t = this.getBoundingRect()
            , n = {
            left: e.x,
            right: e.x + e.w,
            top: e.y,
            bottom: e.y + e.h
        }
            , o = {
            left: t.x,
            right: t.x + t.w,
            top: t.y,
            bottom: t.y + t.h
        };
        return Math.max(0, Math.min(n.right, o.right) - Math.max(n.left, o.left)) * Math.max(0, Math.min(n.bottom, o.bottom) - Math.max(n.top, o.top)) > 2 * (e.w * e.h) / 3
    }
    contains(e, t) {
        var n = this.boardContext ? this.boardContext.scale : 1
            , o = this.getThickness();
        return (e >= this.x - o && e <= this.x + o || e >= this.x + this.w - o && e <= this.x + this.w + o) && t >= this.y - o && t <= this.y + this.h + o || (t >= this.y - o - MS.headerH / n && t <= this.y + o || t >= this.y + this.h - o && t <= this.y + this.h + o) && e >= this.x - o && e <= this.x + this.w + o
    }
    hitTest(e, t) {
        return e >= this.x && e <= this.x + this.w && t + 3 >= this.y && t + 3 <= this.y + this.h
    }
    getText() {
        return this.title
    }
    _isRTL() {
        return void 0 === this._cached.dir && (this._cached.dir = IS.A.isRTL(this.title)),
            this._cached.dir
    }
    draw(e) {
        var t = this.boardContext ? this.boardContext.scale : 1
            , n = this._isRTL() ? MS.rtlFont.clone() : MS.font.clone();
        n.size = n.size / t;
        var o = e.direction
            , a = e.textAlign;
        this._isRTL() && (e.direction = "rtl",
            e.textAlign = "left"),
            e.font = n.full,
            e.textBaseline = "top",
            e.fillStyle = "#797979";
        var r = this.x
            , i = "";
        for (var s of this.title)
            r < this.x + this.w && (r += e.measureText(s).width,
                i += s);
        i.length < this.title.length && (i = i.slice(0, -3) + "..."),
            e.fillText(i, this.x, this.y - MS.headerH / t),
            e.direction = o,
            e.textAlign = a,
            e.lineWidth = 1,
            e.strokeStyle = "#D0D0D0",
        t <= .5 && (e.strokeStyle = "#404040"),
        t <= .1 && (e.lineWidth = 2),
            e.strokeRect(this.x, this.y, this.w, this.h),
        "" !== this.color && (e.fillStyle = this.color,
            e.fillRect(this.x, this.y, this.w, this.h))
    }
    toJSON() {
        return super.toJSON({
            cmd: "frame",
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            color: this.color,
            title: this.title,
            order: this.order,
            children: this.children
        })
    }
    clone() {
        return super.addMetadata(new NimaFrame(this.x,this.y,this.w,this.h,this.title,this.color,this.order,this.children.map((e => e))), this)
    }
    clone2() {
        return super.clone2(new NimaFrame(this.x,this.y,this.w,this.h,this.title,this.color,this.order,this.children.map((e => e))))
    }
}