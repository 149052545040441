import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { LayerList } from '../../layerList/LayerList';
import clsx from 'clsx';
import { TOOLBAR_DISPACH_ACTIONS } from '../../../helpers/Constant';
import useOutsideClick from '../../../hooks/UseOutsideClick2';
import { IconLayer } from '../../svgIcons/IconLayer';
import { useSelector } from 'react-redux';
export const ChangeShapeLayer = ({ isDisabled, showMenu, toggleMenu, layerId, onChange }) => {
    const rootRef = useRef(null);
    const listRef = useRef(null);
    const wrapperRef = useRef(null);
    const tooltipRef = useRef(null);
    const layersListData = useSelector((state) => state?.layers?.list);
    const showHideMenuHandler = () => {
        if (isDisabled) {
            return;
        }
        if (showMenu) {
            toggleMenu(TOOLBAR_DISPACH_ACTIONS.CLOSE_AVAILABLE_LAYERS_LIST);
        } else {
            toggleMenu(TOOLBAR_DISPACH_ACTIONS.SHOW_AVAILABLE_LAYERS_LIST);
        }
    };

    useOutsideClick(rootRef, () => {
        if (showMenu) {
            toggleMenu(TOOLBAR_DISPACH_ACTIONS.CLOSE_AVAILABLE_LAYERS_LIST);
        }
    });

    useLayoutEffect(() => {
        const list = listRef.current;
        if (!list || !showMenu) return;
        const clientRect = list.getBoundingClientRect();
        if (clientRect.bottom + 10 > window.innerHeight) {
            list.classList.add('onBottom');
            wrapperRef?.current && wrapperRef.current.classList.add('onBottom');
            tooltipRef?.current && tooltipRef.current.classList.add('onBottom');
        } else {
            tooltipRef.current.classList.remove('onBottom');
        }
    }, [showMenu]);

    return (
        <>
            <div className='layer-container' ref={showMenu ? rootRef : null}>
                <div className={clsx('layer-btn button', { disabled: isDisabled })} onClick={showHideMenuHandler}>
                    <IconLayer />
                    <span className='tooltipText' ref={tooltipRef}>
                        Update layer
                    </span>
                </div>
                {!isDisabled && showMenu ? (
                    <div className='layer-list' ref={listRef}>
                        <div className='layer-list-wrapper' ref={wrapperRef}>
                            <LayerList selectedLayerId={layerId} layersList={layersListData} onChange={onChange} />
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};
ChangeShapeLayer.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    showMenu: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired.isRequired,
    layerId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
