/* eslint-disable */
import {NimaComposite} from "./nimaComposite";

const l = class {
    static processDependencies(e) {
        if (e.command.dependencies)
            for (var t of e.command.dependencies) {
                var n = e.get(t.shape);
                null != n && (n.points = t.points)
            }
    }
}

const ik = class {
    move(e, t, n) {
        var o = Number.MAX_SAFE_INTEGER
            , a = Number.MAX_SAFE_INTEGER;
        for (var r of e) {
            var i = r.getBoundingRect({
                skipTransform: !0
            });
            o = Math.min(i.x, o),
                a = Math.min(i.y, a)
        }
        var s = o - t
            , l = a - n;
        for (var c of e) {
            var d = c.getBoundingRect({
                skipTransform: !0
            });
            c.updatePosition(d.x - s, d.y - l)
        }
    }
    move2(e, t, n) {
        for (var o of e) {
            var a = o.getBoundingRect({
                skipTransform: !0
            });
            o.updatePosition(a.x - t, a.y - n)
        }
    }
}

export class move {
    execute(e) {
        var t = new ik
            , n = [];
        for (var o of e.command.shapes) {
            var a = e.get(o);
            null != a && n.push(a)
        }
        void 0 !== e.command.dx && void 0 !== e.command.dy ? t.move2(n, e.command.dx, e.command.dy) : t.move(n, e.command.x, e.command.y),
            l.processDependencies(e)
    }
}

export class undo {
    execute(e) {
        var t = e.getCommand(e.command.ref);
        t && (e.remove(e.getIndex(e.command.ref), 1),
            e.disableCommand(t))
    }
}

export class resize {
    execute(e) {
        var t = [];
        for (var n of e.command.shapes) {
            var o = e.get(n);
            null != o && t.push(o)
        }
        if (new NimaComposite(t).updateSize(e.command.x, e.command.y, e.command.w, e.command.h),
            l.processDependencies(e),
            e.command.innerContents)
            for (var a of e.command.innerContents) {
                var r = e.get(a.shape);
                if (null != r)
                    for (var i of a.props)
                        r[i.name] = i.value
            }
    }
}

export class commandSet {
    execute(e) {
        for (var t of e.command.commands)
            e.invoke(t)
    }
}

const Ck = class {
        execute(e) {
            var t = e.getCommand(e.command.ref);
            t && (e.remove(e.getIndex(e.command.ref), 1),
                e.disableCommand(t))
        }
    }
;
export class Clear {
    execute(e) {
        e.disableAllCommands()
    }
}

export class Remove {
    execute(e) {
        for (var t of e.command.shapes)
            e.remove(e.getIndex(t), 1)
    }
}
export class TextModifier {
    execute(e) {
        var t = e.get(e.command.shape);
        null != t && null != e.command.value && (t.value = e.command.value)
    }
}
export class StickyNodeModifier {
    execute(e) {
        var t = e.get(e.command.shape);
        null != t && (null != e.command.text && (t.text = e.command.text),
        null != e.command.fontSize && (t.fontSize = e.command.fontSize))
    }
}

export class ColorModifier {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t);
            null != n && ("fill"in e.command && (n.fill = e.command.fill),
            "stroke"in e.command && (n.stroke = e.command.stroke),
            "color"in e.command && (n.v1 && "e" === n.v1.type ? n.stroke = e.command.color : n.v1 && "s" === n.v1.type ? n.fill = e.command.color : n.color = e.command.color))
        }
    }
}

export class FontModifier {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t);
            null != n && (n.font = e.command.font)
        }
        l.processDependencies(e)
    }
}
export class FontSizeModifier {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t);
            null != n && (n.size = e.command.size)
        }
        l.processDependencies(e)
    }
}
export class SizeModifier {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t);
            null != n && (n.w = e.command.w,
            n.h = e.command.h)
        }
        if (l.processDependencies(e),
        e.command.innerContents)
            for (var o of e.command.innerContents) {
                var a = e.get(o.shape);
                if (null != a)
                    for (var r of o.props)
                        a[r.name] = r.value
            }
    }
}

export class PageModifier {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t);
            null != n && (n.metadata.wtapp_doc.page.current = e.command.page)
        }
    }
}

export class PropertyModifier {
    execute(e) {
        var t = (e,n,o,a)=>{
            if (1 !== n.length)
                e[n[0]] || (console.error('PropertyModifierCommand', 'here we are'),
                e[n[0]] = {}),
                t(e[n[0]], n.slice(1), o);
            else if ('add' === a)
                e[n] || (e[n] = []),
                e[n].push(o);
            else if ('del' === a)
                if (o.key) {
                    var r = e[n].findIndex((e=>e.key === o.key));
                    e[n].splice(r, 1)
                } else {
                    var i = e[n].findIndex((e=>e === o));
                    e[n].splice(i, 1)
                }
            else
                e[n] = o
        }
        ;
        for (var n of e.command.shapes) {
            var o = e.get(n);
            if (void 0 !== o)
                for (var a of e.command.props)
                    t(o, a.name.split('.'), a.value, a.type)
        }
    }
}
export class Replace {
    execute(e) {
        var t = e.get(e.command.shape);
        if (null != t) {
            var n = e.getIndex(t.id);
            e.remove(n, 1),
            e.insert(n, e.command.newShape)
        }
    }
}
export class Lock {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t);
            null != n && (n.lock = e.command.locked,
            !e.command.locked && n.protectedBy && (n.protectedBy = null))
        }
    }
}
export class ChangeOrder {
    execute(e) {
        var t = 'up' === e.command.dir ? e.command.shapes.slice().reverse() : e.command.shapes;
        for (var n of t)
            e.swap(n, e.command.dir)
    }
}
export class Reposition {
    execute(e) {
        var t = [];
        for (var n of e.command.shapePositions) {
            var o = e.get(n.shape);
            null != o && t.push({
                shape: o,
                x: n.x,
                y: n.y
            })
        }
        for (var a of t)
            a.shape.updatePosition(a.x, a.y);
        l.processDependencies(e)
    }
}
export class Rotate {
    execute(e) {
        for (var t of e.command.shapes) {
            var n = e.get(t.id);
            if (void 0 !== n) {
                var o = n.getBoundingRect({
                    skipTransform: !0
                });
                n.updatePosition(t.pos.x - o.w / 2, t.pos.y - o.h / 2),
                n.rotatable && (n.rotation = (n.rotation || 0) + e.command.rotation),
                l.processDependencies(e)
            }
        }
    }
}
const Lk = class {
    execute(e) {
        for (var t of e.command.commands)
            e.invoke(t)
    }
}