import { useEffect, useState } from 'react';
import './LayerList.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Scrollbar from 'react-scrollbars-custom';
export const LayerList = ({ layersList, selectedLayerId, onChange }) => {
    const [layerListData, setLayerListData] = useState(JSON.parse(JSON.stringify(layersList)));

    useEffect(() => {
        if (selectedLayerId) {
            const layerListCopy = JSON.parse(JSON.stringify(layersList));
            const index = layerListCopy.findIndex((l) => l.uuid === selectedLayerId);
            if (index > -1) {
                const [selectedLayer] = layerListCopy.splice(index, 1);
                layerListCopy.unshift(selectedLayer);
                setLayerListData(layerListCopy);
            }
        }
    }, [selectedLayerId, layersList]);

    const shouldShowScrollbar = layerListData.length > 5;
    // below code needs to be make efficient

    return (
        <div className='layer-list-container'>
            {shouldShowScrollbar ? (
                <Scrollbar style={{ height: '140px' }}>
                    <div className='layer-list-main'>
                        {layerListData.map((layer) => (
                            <div
                                key={layer.uuid}
                                className={clsx('layer', { selected: selectedLayerId === layer.uuid })}
                                title={layer.name}
                                onClick={() => {
                                    if (selectedLayerId !== layer.uuid) {
                                        onChange(layer.uuid);
                                    }
                                }}
                            >
                                <p className='name'>{layer.name}</p>
                            </div>
                        ))}
                    </div>
                </Scrollbar>
            ) : (
                <div className='layer-list-main'>
                    {layerListData.map((layer) => (
                        <div
                            key={layer.uuid}
                            className={clsx('layer', { selected: selectedLayerId === layer.uuid })}
                            title={layer.name}
                            onClick={() => {
                                if (selectedLayerId !== layer.uuid) {
                                    onChange(layer.uuid);
                                }
                            }}
                        >
                            <p className='name'>{layer.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

LayerList.propTypes = {
    selectedLayerId: PropTypes.string,
    layersList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
};
