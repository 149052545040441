/* eslint-disable */

import {level2} from "./nimaRectangle";

const TextHelper = class {
    static measureBaseline(e, t) {
        var n = document.createElement("div");
        n.style.position = "relative";
        var o = document.createElement("div");
        o.innerText = "Hdxp",
            o.style.fontSize = e,
            o.style.fontFamily = t,
            o.style.lineHeight = "100%",
            n.appendChild(o);
        var a = document.createElement("div");
        a.style.position = "absolute",
            a.style.width = "100%",
            a.style.top = "0";
        var r = document.createElement("span");
        r.style.fontSize = e,
            r.style.fontFamily = t,
            r.style.lineHeight = "100%",
            a.appendChild(r);
        var i = document.createElement("div");
        i.style.fontSize = e,
            i.style.fontFamily = t,
            i.style.lineHeight = "100%",
            i.style.display = "inline-block",
            i.style.width = "500px",
            i.style.height = "100%",
            i.style.verticalAlign = "baseline",
            a.appendChild(i),
            n.appendChild(a),
            document.getElementsByTagName("BODY")[0].appendChild(n);
        var s = i.offsetTop;
        return n.remove(),
            s
    }
    static measure(e, t, n) {
        var o = document.createElement("span");
        o.style.display = "inline-block",
            o.style.padding = 0,
            o.style.margin = 0,
            o.style.fontFamily = t,
            o.style.fontSize = n,
            o.style.lineHeight = "100%",
            o.style.whiteSpace = "pre",
            o.innerHTML = e,
            document.body.appendChild(o);
        var a = {
            w: o.offsetWidth,
            h: o.offsetHeight
        };
        return document.body.removeChild(o),
            a
    }
    static isRTL(e) {
        return new RegExp("^[^A-Za-zÀ-ÖØ-öø-ʸ̀-֐ࠀ-῿Ⰰ-﬜﷾-﹯﻽-￿]*[֑-߿יִ-﷽ﹰ-ﻼ]").test(e)
    }
}

export class NimaText extends level2 {
    constructor(e, t, n, o, a, r, i) {
        super(),
            this.x = t,
            this.y = n,
            this.value = e,
            this.color = o,
            this.size = r,
            this.font = a,
            this.cmd = "text",
        i && "left" !== i && (this.align = i),
            this._cached = {}
    }

    set font(e) {
        this._cached = {},
            this._font = e
    }

    get font() {
        return this._font
    }

    set size(e) {
        this._cached = {},
            this._size = e
    }

    get size() {
        return this._size
    }

    set value(e) {
        this._cached = {},
            this._value = e
    }

    get value() {
        return this._value
    }

    get rotatable() {
        return !0
    }

    getAspectRatio(e) {
        return "preserved"
    }

    updateSize(e, t, n, o) {
        var a = n / this.getBoundingRect().w;
        this.size = this.size * a,
            this.updatePosition(e, t)
    }

    updatePosition(e, t) {
        this.x = e,
            this.y = t
    }

    static GetTextWidth(e) {
        var t = e.split("\n");
        return {
            text: t.sort(((e, t) => e.length - t.length))[t.length - 1],
            length: t.length
        }
    }

    _getTextMeasure() {
        if (!this._cached.measure) {
            var e = NimaText.GetTextWidth(this.value);
            this._cached.measure = {
                size: TextHelper.measure(e.text, this.font, this.size + "pt"),
                length: e.length
            }
        }
        return this._cached.measure
    }

    _getLineHeight(e) {
        return this._cached.lineHeight || (this._cached.lineHeight = TextHelper.measure(e, this.font, this.size + "pt").h),
            this._cached.lineHeight
    }

    _getBaseLine() {
        return this._cached.baseLine || (this._cached.baseLine = TextHelper.measureBaseline(this.size + "pt", this.font)),
            this._cached.baseLine
    }

    _getWidths(e, t) {
        return this._cached.widths || (this._cached.widths = e.map((e => t.measureText(e).width))),
            this._cached.widths
    }

    _isRTL() {
        return void 0 === this._cached.dir && (this._cached.dir = TextHelper.isRTL(this.value)),
            this._cached.dir
    }

    getBoundingRect(e) {
        var t = e && e.skipTransform
            , n = this._getTextMeasure()
            , o = {
            x: this.x,
            y: this.y,
            w: n.size.w,
            h: n.size.h * n.length
        };
        return !t && this.rotation ? this.rotateRect(o) : o
    }

    contains(e, t) {
        var n = this._getTextMeasure();
        return [e, t] = this.getRotatedPoints(e, t),
        e >= this.x && e <= this.x + n.size.w && t >= this.y && t <= this.y + n.size.h * n.length
    }

    getText() {
        return this.value
    }

    draw(e) {
        this.startRotate(e),
            e.fillStyle = this.color,
            e.font = this.size + "pt " + this.font,
            e.textBaseline = "alphabetic",
            e.direction = "ltr",
        this._isRTL() && (e.direction = "rtl"),
            e.textAlign = "left";
        var t = this._getBaseLine()
            , n = this.value.split("\n")
            , o = this._getLineHeight(n[0])
            , a = this._getWidths(n, e)
            , r = Math.max(...a)
            , s = this.y + t
            , l = !!this.boardContext && this.boardContext.isBlurry(this);
        for (var [c, d] of n.entries()) {
            var p = this.x;
            if ("right" === this.align ? p -= a[c] - r : "center" === this.align && (p -= a[c] / 2 - r / 2),
                l)
                (new i.A).drawWireframeText(e, d, p, s, this.size, this.color);
            else
                e.fillText(d, p, s);
            s += o
        }
        this.endRotate(e)
    }

    toJSON() {
        return super.toJSON({
            cmd: "text",
            x: this.x,
            y: this.y,
            color: this.color,
            size: this.size,
            font: this.font,
            value: this.value,
            align: this.align
        })
    }

    clone() {
        return super.addMetadata(new NimaText(this.value, this.x, this.y, this.color, this.font, this.size, this.align), this)
    }

    clone2() {
        return super.clone2(new NimaText(this.value, this.x, this.y, this.color, this.font, this.size, this.align))
    }
}