import PropTypes from 'prop-types';

const IconPencil = ({height=14, width=14}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.095 1.905a1.82 1.82 0 0 0-2.585.018l-7.25 7.25-.885 3.452 3.452-.885 7.25-7.25a1.82 1.82 0 0 0 .018-2.585"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.303 2.13 2.567 2.567M2.26 9.172l2.57 2.565"
            ></path>
        </svg>
    )
}

IconPencil.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number
};

export default IconPencil;