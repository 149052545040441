import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import useMove from '../../hooks/UseMove';
import './LayersWidget.scss';
import DragItemIcon from '../../assets/images/drag-item.svg';
import CloseIcon from '../../assets/images/layers/close.svg';
import IconPlus from '../svgIcons/IconPlus';
import LayersList from './layersList/LayersList';
import LayersAddInput from './layersAddInput/LayersAddInput';
import {useDispatch, useSelector} from 'react-redux';
import eventEmitter from '../../helpers/EventEmitter';
import {EMITTER_TYPES, MODAL_WINDOWS} from '../../helpers/Constant';
import {isUserHasAccessToFeature} from '../../helpers/CommonFunctions';
import clsx from 'clsx';

const LayersWidget = ({
    userAccessRef ,
    socketRef
}) => {
    const [showInputProps, setShowInputProps] = useState({
        show: false,
        confirmBtnDisabled: true
    });
    const widgetRef = useRef();
    const confirmBtnPlaceRef = useRef();
    const activeWindowState = useSelector(state => state?.modal?.activeWindow);
    const isLeftDrawerOpen = activeWindowState === MODAL_WINDOWS.ACTIVITY_LOGS;


    const dispatch = useDispatch();
    
    const hideAddNewLayerInput = () => {
        setShowInputProps(oldState => ({ ...oldState, show: false }));
    }
    
    // make layers widget is draggable using ref and .dragger class name
    useMove(widgetRef);

    useEffect(() => {
        const resetLayersWidgetPositionHandler = () => {
            widgetRef.current.style.left = '10%';
            widgetRef.current.style.top = '20%';
        }

        eventEmitter.on(EMITTER_TYPES.RESET_LAYERS_WIDGET_POSITION, resetLayersWidgetPositionHandler)
        
        return () => {
            eventEmitter.off(EMITTER_TYPES.RESET_LAYERS_WIDGET_POSITION, resetLayersWidgetPositionHandler)
        }
    }, []);
    
    return (
        <div 
            className={clsx('layers_widget', {
                leftDrawerActive: isLeftDrawerOpen,
            })}
            ref={widgetRef}>
            <div className='layers_widget__scrollable_header'>
                <img className='dragger_img dragger' src={DragItemIcon} alt='drag layers widget' draggable={false} />
            </div>
            <div className='layers_widget__header'>
                <h2>Layers</h2>
                <button 
                    onClick={() => {
                        dispatch({
                            type: 'modal/toggleLayersWidget',
                            payload: false
                        })
                    }}
                    type='button'
                    aria-labelledby='closerLayerWidgetImg'>
                    <img src={CloseIcon} alt='close layers widget' draggable={false} id='closerLayerWidgetImg'/>
                </button>
            </div>
            <div className='layers_widget__separator' />
            <div className='layers_widget__content'>
                { 
                    showInputProps?.show ? 
                        <LayersAddInput 
                            confirmBtnPlaceRef={confirmBtnPlaceRef} 
                            hide={hideAddNewLayerInput}
                            userAccessRef={userAccessRef}
                            socketRef={socketRef}
                        /> 
                        : null
                }
                <LayersList userAccessRef={userAccessRef} socketRef={socketRef}/>
            </div>
            <div className='layers_widget__separator' />
            <div className='layers_widget__footer'>
                <div ref={confirmBtnPlaceRef} >
                    {
                        isUserHasAccessToFeature('layers_add_new_layer', userAccessRef.current) && !showInputProps?.show ? (
                            <button
                                type='button'
                                aria-label='add new layer'
                                onClick={() => {
                                    setShowInputProps(oldState => ({...oldState, show: true}))
                                }}
                            >
                                <IconPlus />
                            </button>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

LayersWidget.propTypes = {
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }).isRequired,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }).isRequired
}

export default LayersWidget;