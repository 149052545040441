import { fabric } from 'fabric';

/**
 * Custom text shape for table cells.
 */
export const CellText = fabric.util.createClass(fabric.Textbox, {
    type: 'cellText',
    shapeType: 'cellText',
    objectCaching: false,
    initialize: function(text, options) {
        options.backgroundColor = 'transparent';
        this.callSuper('initialize', text, options);
    },
    initDimensions: function() {
        if (this.__skipDimension) {
            return;
        }
        this.isEditing && this.initDelayedCursor();
        this.clearContextTop();
        this._clearCache();
        // clear dynamicMinWidth as it will be different after we re-wrap line
        this.dynamicMinWidth = 0;
        // wrap lines
        this._styleMap = this._generateStyleMap(this._splitText());
        // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
        if (this.dynamicMinWidth > this.width) {
            this._set('width', this.dynamicMinWidth);
        }
        if (this.textAlign.indexOf('justify') !== -1) {
            // once text is measured we need to make space fatter to make justified text.
            this.enlargeSpaces();
        }
        // clear cache and re-calculate height
        // we need restrictedHeight and heightPadding for defining text height
        if (this.restrictedHeight) {
            this.height = this.restrictedHeight; // - this.heightPadding * 2;
        } else {
            this.height = this.calcTextHeight();
        }
        this.saveState({ propertySet: '_dimensionAffectingProps' });
    },
    /**
     * @override
     * @param {CanvasRenderingContext2D} ctx - Context to render on.
     * @param {string} method - Method name ("fillText" or "strokeText").
     */
    _renderTextCommon: function(ctx, method) {
        ctx.save();

        // create a clipping region for the cell so that the text doesn't overflow
        ctx.beginPath()
        ctx.rect(
            -this.width / 2,
            -this.height / 2,
            this.width,
            this.height
        )
        ctx.clip();

        var lineHeights = 0, left = this._getLeftOffset(), top = this._getTopOffset();
        this.hyperLinkPositions = [];
        for (var i = 0, len = this._textLines.length; i < len; i++) {
            var heightOfLine = this.getHeightOfLine(i),
                maxHeight = heightOfLine / this.lineHeight,
                leftOffset = this._getLineLeftOffset(i);
            const newTextTopPosition = top + lineHeights + maxHeight;

            // if the line is overriding the cell height, don't render it
            if (newTextTopPosition + this.height / 2 <= this.height) {
                this._renderTextLine(
                    method,
                    ctx,
                    this._textLines[i],
                    left + leftOffset,
                    top + lineHeights + maxHeight,
                    i
                );
                lineHeights += heightOfLine;
            }
        }
        ctx.restore();
    },
});