import { useState, useEffect, useMemo } from 'react';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { loggedInUser } from '../../services/AuthService';
import { deleteCookie } from '../../services/CookieService';
import { createWhiteBoard, getBoardListing } from '../../services/DataService';
import store from '../../redux/Store';
import BoardListingSidebar from './BoardListingSidebar';
import BoardListingContent from './BoardListingContent';
import MENU_ITEMS from './constants/MenuItems';
import './BoardListing.scss';
import LoadingScreen from '../loader/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDocumentTitle from '../../hooks/UseDocumentTitle';

/**
 *
 */
const BoardListing = () => {
    const [boards, setBoards] = useState({ myBoards: [], invitedBoards: [], allBoards: [], deletedBoards: [] });
    const [navPage, setNavPage] = useState('ALL_CANVASES');
    const [isCreateNewBoardLoading, setIsCreateNewBoardLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    useDocumentTitle({});
    
    /**
     *@param {username}
     */
    const handleGetBoardListing = (username) => {
        getBoardListing()
            .then((data) => {
                setBoards({ ...data });
                setUsername(username === data?.userInfo?.name ? username : data?.userInfo?.name)
            }).finally(() => setIsLoading(false));
    }

    useEffect(() => {
        const loggedUser = loggedInUser();
        if (!loggedUser) {
            navigate('/');
        }  else {
            const initialPage = searchParams.get('page');
            if (initialPage && !!MENU_ITEMS[initialPage]) {
                setNavPage(initialPage);
            }
            handleGetBoardListing(loggedUser.name);
            store.dispatch({ type: 'layers/clearState'});
            
        }
    }, []);

    const activeBoards = useMemo(() => {

        if (navPage === 'ALL_CANVASES') {
            return boards.allBoards;
        } else if (navPage === 'MY_CANVASES') {
            return boards.myBoards;
        } else if (navPage === 'SHARED_WITH_ME') {
            return boards.invitedBoards
        } else if (navPage === 'TRASH') {
            return boards.deletedBoards
        }
    
    }, [boards.myBoards, boards.invitedBoards, boards.allBoards, boards.deletedBoards, navPage]);

    const createNewBoard = () => {
    // if already creating a board, then do nothing
        if (isCreateNewBoardLoading) return;

        setIsCreateNewBoardLoading(true);

        createWhiteBoard().then(data => {
            navigate(`/board/${data.wbId}`);
        }).finally(() => {
            setIsCreateNewBoardLoading(false);
        });
    }

    const navSelected = (page) => {
        setNavPage(page);

        navigate({
            pathname: '/boards',
            search: createSearchParams({
                page
            }).toString()
        });
    }

    const logout = () => {
        deleteCookie();
        store.dispatch({ type: 'user/setNull', payload: {}});
        navigate('/');
    }

    const goToBoard = (boardId) => {
        navigate('/board/' + boardId);
    }

    return (
        <div className="whiteboardWrapper">
            <BoardListingSidebar
                activePage={navPage}
                activeProjectId="1"
                logout={logout}
                onActivePageChange={navSelected}
                projects={[]}
                userName={username}
            />

            {
                isLoading ?
                    <div className="listing-loading-screen">
                        <LoadingScreen/>
                    </div> :
                    <BoardListingContent
                        activePage={navPage}
                        boards={activeBoards}
                        createNewBoard={createNewBoard}
                        isLoading={isLoading}
                        onBoardSelected={goToBoard}
                        userName={username}
                        handleGetBoardListing={handleGetBoardListing}
                    />
            }
            <ToastContainer
                className="toast-container board-toast-container"
                hideProgressBar
                draggable={false}
                autoClose={4000}
                closeOnClick={false}
                position="bottom-center"
            />
        </div>
    );
}

export default BoardListing;