import React from 'react';
import PropTypes from 'prop-types';

const InfoCircle = ({
    width = 16,
    height = 16
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6459_188530)">
            <ellipse cx="8.00033" cy="7.99984" rx="6.79915" ry="6.79915" stroke="#676B7E" />
            <path d="M6.33325 6.6665H7.99992V11.9998" stroke="#676B7E" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 12H10" stroke="#676B7E" strokeLinecap="round" strokeLinejoin="round" />
            <ellipse cx="7.90877" cy="4.1766" rx="0.934896" ry="0.934896" fill="#676B7E" />
        </g>
        <defs>
            <clipPath id="clip0_6459_188530">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

InfoCircle.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}

export default InfoCircle;
