import './LayersAddInput.scss'
import {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';
import IconTick2 from '../../svgIcons/IconTick2';
import {isUserHasAccessToFeature} from '../../../helpers/CommonFunctions';
import {useDispatch, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import getToastIcon from '../../../helpers/media/GetToastIcon';
import { v4 as uuidv4 } from 'uuid';
import { SOCKET_EVENT } from '../../../helpers/Constant';

const LayersAddInput = ({
    confirmBtnPlaceRef,
    hide,
    userAccessRef,
    socketRef
}) => {
    const inputRef = useRef();
    const addBtnRef = useRef();
    const [value, setValue] = useState('')
    const [disabled, setDisabled] = useState(true);
    const layers = useSelector((state) => state?.layers?.list);
    const dispatch = useDispatch();
    const toastIdRef = useRef();
    
    const onAddLayer = () => {
        const name = value.trim();
        if (name?.length) {
            if(name.length <= 0 || name.length >= 16){
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = toast.error('Layer name must be between 1 and 15 characters', {
                    icon: getToastIcon('error'),
                    autoClose: true,
                    className: 'wb_toast',
                    draggable: false,
                    toastId: `add-layer-error-${uuidv4()}-${Math.random()}`,
                });
            }
            else if (name.toLocaleLowerCase() === 'default' || layers.some((l) => l.name.toLocaleLowerCase() === name.toLocaleLowerCase())) {
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = toast.error('Please use a different layer name', {
                    icon: getToastIcon('error'),
                    autoClose: true,
                    className: 'wb_toast',
                    draggable: false,
                    toastId: `add-layer-error-${uuidv4()}-${Math.random()}`,
                });
            } else {
                socketRef.current.emit(
                    SOCKET_EVENT.CREATE_LAYER,
                    {
                        name,
                    },
                    (resp) => {
                        const layerId = resp?.emitData?.uuid;
                        if(layerId){
                            dispatch({
                                type: 'layers/add',
                                payload: {
                                    name,
                                    uuid: layerId
                                }
                            })
                        }
                    }
                );
            }
        }
        hide();
    };
    
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            onAddLayer();
        } else if (e.key === 'Escape') {
            hide()
        }
    }
    
    const onBlurHandler = (e) => {
        if (e.relatedTarget === addBtnRef.current) {
            onAddLayer()
        }
        hide()
    }

    const textOnChangeHandler = (e) => {
        const trimmedVal = e.target.value?.trim();
        if (trimmedVal?.length > 15) {
            const newText = e.target.value.trim().substring(0, 15);
            setValue(newText);
            return;
        }
        setValue(e.target.value);
    }
    
    useEffect(() => {
        inputRef.current.focus();
    }, [])
    
    useEffect(() => {
        if (value.trim().length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [value])

    return (
        <div className='layers_widget_add_input'>
            <input
                className='layers_widget_add_input--input'
                type='text'
                autoComplete='false'
                placeholder='Add layer name'
                value={value}
                ref={inputRef}
                onBlur={onBlurHandler}
                onChange={(e) => textOnChangeHandler(e)}
                onKeyUp={handleKeyUp}
                minLength={1}
            />
            <div className='layers_content_separator' style={{ marginTop: '12px', marginBottom: '12px' }}/>
            {isUserHasAccessToFeature('layers_add_new_layer', userAccessRef.current) && confirmBtnPlaceRef?.current ? createPortal(
                <button
                    type='submit'
                    aria-label='add new layer'
                    className='confirmButton'
                    disabled={disabled}
                    onClick={() => onAddLayer()}
                    ref={addBtnRef}
                >
                    <IconTick2 width={14} height={14}/>
                    Add
                </button>,
                confirmBtnPlaceRef.current
            ): null}
        </div>
    )
}

LayersAddInput.propTypes = {
    confirmBtnPlaceRef: PropTypes.func,
    hide: PropTypes.func,
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }).isRequired,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }).isRequired
}


export default LayersAddInput