/* eslint-disable */

import {level2} from "./nimaRectangle";

export class NimaLine extends level2 {
    constructor(e, t, n, o) {
        super(),
            this.points = e,
            this.color = t,
            this.lineWidth = n,
            this.cmd = "line",
        null == o && (o = "line"),
            this.type = o
    }
    get isConnector() {
        return void 0 !== this.endpoints
    }
    getThickness() {
        return this.lineWidth / 2
    }
    updateSize(e, t, n, o) {
        var a = this.getThickness();
        this.points[0].x < this.points[1].x ? (this.points[0].x = e + a,
            this.points[1].x = e + n - a) : this.points[0].x > this.points[1].x && (this.points[0].x = e + n + a,
            this.points[1].x = e - a),
            this.points[0].y < this.points[1].y ? (this.points[0].y = t + a,
                this.points[1].y = t + o - a) : this.points[0].y > this.points[1].y && (this.points[0].y = t + o + a,
                this.points[1].y = t - a)
    }
    updatePosition(e, t) {
        var n = this.getBoundingRect();
        this.points[0].x = this.points[0].x - (n.x - e),
            this.points[0].y = this.points[0].y - (n.y - t),
            this.points[1].x = this.points[1].x - (n.x - e),
            this.points[1].y = this.points[1].y - (n.y - t)
    }
    getBoundingRect() {
        var e = this.getThickness()
            , t = Math.min(this.points[0].x, this.points[1].x)
            , n = Math.min(this.points[0].y, this.points[1].y)
            , o = Math.max(this.points[0].x, this.points[1].x)
            , a = Math.max(this.points[0].y, this.points[1].y);
        return {
            x: t - e,
            y: n - e,
            w: o - t + this.lineWidth,
            h: a - n + this.lineWidth
        }
    }
    contains(e, t) {
        var n = (e, t, n, o) => Math.sqrt(Math.pow(e - n, 2) + Math.pow(t - o, 2))
            , o = this.getThickness()
            , a = 1 * o;
        return Math.abs(n(this.points[0].x, this.points[0].y - o, this.points[1].x, this.points[1].y + o) - (n(this.points[0].x, this.points[0].y - o, e, t) + n(this.points[1].x, this.points[1].y + o, e, t))) <= a
    }
    draw(e) {
        super.setStyle(e),
            e.fillStyle = this.color;
        var t, n, o, a, r = (t, n, o, a) => {
                e.beginPath();
                var r = o - t
                    , i = a - n
                    , s = Math.atan2(i, r);
                e.moveTo(t, n),
                    e.lineTo(o, a),
                    e.lineTo(o - 10 * Math.cos(s - Math.PI / 6), a - 10 * Math.sin(s - Math.PI / 6)),
                    e.moveTo(o, a),
                    e.lineTo(o - 10 * Math.cos(s + Math.PI / 6), a - 10 * Math.sin(s + Math.PI / 6)),
                    e.closePath(),
                    e.stroke()
            }
            , i = () => {
                e.beginPath(),
                    e.moveTo(this.points[0].x, this.points[0].y),
                    e.lineTo(this.points[1].x, this.points[1].y),
                    e.stroke()
            }
        ;
        if ("line" === this.type)
            i();
        else if ("dashed" === this.type)
            e.setLineDash([4 * this.lineWidth, 4 * this.lineWidth]),
                i(),
                e.setLineDash([]);
        else if ("dotted" === this.type) {
            var s = (t = this.points[0].x,
                n = this.points[0].y,
                o = this.points[1].x,
                a = this.points[1].y,
                Math.sqrt((t -= o) * t + (n -= a) * n));
            ( (t, n, o, a, r, i) => {
                    for (var s = (t, n, o) => {
                        e.beginPath(),
                            e.arc(t, n, o, 0, 2 * Math.PI, !1),
                            e.fill()
                    }
                             , l = (o - t) / (i - 1), c = (a - n) / (i - 1), d = t, p = n, u = 0; u < i; u++)
                        s(d, p, r),
                            d += l,
                            p += c
                }
            )(this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y, this.lineWidth, Math.round(s / this.lineWidth / 4))
        } else if ("arrow" === this.type) {
            r(this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y)
        } else if ("rarrow" === this.type) {
            r(this.points[1].x, this.points[1].y, this.points[0].x, this.points[0].y)
        } else {
            r(this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y),
                r(this.points[1].x, this.points[1].y, this.points[0].x, this.points[0].y)
        }
    }
    toJSON() {
        var e = super.toJSON({
            cmd: "line",
            points: this.points.map((e => ({
                x: e.x,
                y: e.y
            }))),
            color: this.color,
            lineWidth: this.lineWidth
        });
        return "line" != this.type ? Object.assign(e, {
            type: this.type
        }) : this.endpoints ? Object.assign(e, {
            endpoints: this.endpoints
        }) : e
    }
    clone() {
        return super.addMetadata(new NimaLine(this.points.map((e => ({
            x: e.x,
            y: e.y
        }))),this.color,this.lineWidth,this.type), this)
    }
    clone2() {
        var e = new NimaLine(this.points.map((e => ({
            x: e.x,
            y: e.y
        }))),this.color,this.lineWidth,this.type);
        return this.endpoints && (e.endpoints = Object.assign({}, this.endpoints)),
            super.clone2(e)
    }
}