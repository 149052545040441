import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IconEye from '../../svgIcons/IconEye';
import './LayersListItem.scss';
import IconPencil from '../../svgIcons/IconPencil';
import clsx from 'clsx';
import IconTick2 from '../../svgIcons/IconTick2';
import PropTypes from 'prop-types';
import {isUserHasAccessToFeature} from '../../../helpers/CommonFunctions';
import { toast } from 'react-toastify';
import getToastIcon from '../../../helpers/media/GetToastIcon';
import { v4 as uuidv4 } from 'uuid';
import eventEmitter from '../../../helpers/EventEmitter';
import { EMITTER_TYPES, SOCKET_EVENT } from '../../../helpers/Constant';

const LayersListItem = ({
    userAccessRef,
    layer,
    socketRef
}) => {
    const editable = true;
    const [isEditing, setIsEditing] = useState(false);
    const [layerName, setLayerName] = useState('');
    
    const layerId = layer.uuid;
    const inputRef = useRef();
    const dispatch = useDispatch();

    const toastIdRef = useRef(null);

    const hasUserChangeLayerNameAccess = isUserHasAccessToFeature('layers_change_layer_name', userAccessRef.current)

    
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);
    const layersState = useSelector((state) => state?.layers);
    const layers = layersState?.list ?? [];
    const pageLevelLayersShapesCount = layersState?.pageLevelLayersShapesCount?.[activePageId] ?? {};
    const currentLayerCount = pageLevelLayersShapesCount?.[layerId]?.count ?? 0;
    const currentLayerVisibility = layersState?.layersShapesVisibility?.[layerId]?.visibility ?? true;    
    const defaultLayerId = layersState?.defaultLayerId;
    
    const inputOnBlurHandler = () => {
        const trimmedName = layerName?.trim();
        if (trimmedName !== layer.name) {
            if (trimmedName?.length > 0) {
                if(trimmedName.length >= 16){
                    toast.dismiss(toastIdRef.current);
                    toastIdRef.current = toast.error('Layer name must be between 1 and 15 characters', {
                        icon: getToastIcon('error'),
                        autoClose: true,
                        className: 'wb_toast',
                        draggable: false,
                        toastId: `rename-layer-name-error-${uuidv4()}-${Math.random()}`,
                    });
                    setLayerName(layer.name);
                }
                else if (layers.some((l) => l.name.toLocaleLowerCase() === trimmedName.toLocaleLowerCase())) {
                    toast.dismiss(toastIdRef.current);
                    toastIdRef.current = toast.error('Please use a different layer name', {
                        icon: getToastIcon('error'),
                        autoClose: true,
                        className: 'wb_toast',
                        draggable: false,
                        toastId: `rename-layer-name-error-${uuidv4()}-${Math.random()}`,
                    });
                    setLayerName(layer.name);
                } else {
                    socketRef.current.emit(
                        SOCKET_EVENT.UPDATE_LAYER,
                        {
                            uuid: layerId,
                            name: trimmedName,
                        },
                        (resp) => {
                            if (resp?.emitData?.uuid) {
                                dispatch({
                                    type: 'layers/changeName',
                                    payload: {
                                        uuid: layerId,
                                        name: layerName.trim(),
                                    },
                                });
                            }
                        }
                    );
                }
            } else {
                setLayerName(layer.name);
            }
        }else{
            setLayerName(layer.name);
        }
        setIsEditing(false);
    };
    
    const startEditing = () => {
        if (!hasUserChangeLayerNameAccess) {
            return
        }

        setIsEditing(true)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            inputOnBlurHandler();
        }
    }

    const textOnChangeHandler = (e) => {
        const trimmedVal = e.target.value?.trim();
        if (trimmedVal?.length > 15) {
            const newText = e.target.value.trim().substring(0, 15);
            setLayerName(newText);
            return;
        }
        setLayerName(e.target.value);
    }


    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus()
            inputRef.current?.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
        }
    }, [isEditing]);
    
    useEffect(() => {
        const getTextWidth = (text, font, fontSize) => {
            const textElForWidth = document.createElement('div')

            const body = document.body;
            textElForWidth.style.position = 'absolute';
            textElForWidth.style.font = font;
            textElForWidth.style.left = '-9999px';
            textElForWidth.style.top = '-9999px';
            textElForWidth.style.fontSize = fontSize;
            textElForWidth.innerHTML = text.replaceAll(' ', '&nbsp;');
            body.appendChild(textElForWidth);
            
            const textWidth = textElForWidth.offsetWidth;
            
            body.removeChild(textElForWidth)
            if (textWidth < 6) {
                return 6
            }
            
            return textWidth + 2;
        }
        if (isEditing) {
            const textWidth = getTextWidth(layerName, 'Rubik, sans-serif', '12px');
            inputRef.current.style.width = textWidth + 'px';
        }
    }, [isEditing, layerName]);

    useEffect(()=>{
        setLayerName(layer.name);
    },[layer.name]);

    return (
        <div className='layers_widget_list_item'>
            <div className='layers_widget_list_item--info'>
                <div className={clsx('list_item_title', {
                    editable
                })}>
                    {
                        !isEditing ? (
                            <>
                                <span className='list_item_title__text' title={layerName}>{layerName}</span>
                                {
                                    hasUserChangeLayerNameAccess ? <button
                                        className='list_item_title__edit_btn'
                                        type='submit'
                                        onMouseDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                        onClick={startEditing} 
                                    >{layerId !== defaultLayerId ? <IconPencil  height={12} width={12}/> : ''}</button> : null
                                }
                                
                            </>
                        ) : (
                            <>
                                <input
                                    type='text'
                                    className='list_item_title__edit_input'
                                    value={layerName}
                                    ref={inputRef}
                                    onBlur={inputOnBlurHandler}
                                    onMouseDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                    onChange={(e) => textOnChangeHandler(e)}
                                    onKeyUp={handleKeyUp}
                                />
                                <button
                                    className='list_item_title__confirm_btn'
                                    type='submit'
                                    onMouseDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <IconTick2 width={12} height={12}/>
                                </button>
                            </>
                        )
                    }
                </div>
                <span className='list_item_info'>{currentLayerCount} {currentLayerCount > 1 ? 'items' : 'item'}</span>
            </div>
            <div className='layers_widget_list_item--actions'>
                <button
                    type='button'
                    title={!currentLayerVisibility ? 'Show layer' : 'Hide layer'}
                    aria-label={!currentLayerVisibility ? 'Show layer' : 'Hide layer'}
                    onMouseDown={(e) => {
                        e.stopPropagation()
                    }}
                    onClick={() => {
                        eventEmitter.fire(EMITTER_TYPES.UPDATE_LAYERS_SHAPES_COUNT_AND_VISIBILITY, { [layerId]: { visibility: !currentLayerVisibility }, action: 'visibilityUpdate' });
                    }}
                >
                    <IconEye closed={!currentLayerVisibility} width={12} height={12}/>
                </button>
            </div>
        </div>
    )
}

LayersListItem.propTypes = {
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }).isRequired,
    layer: PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string,
    }),
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }).isRequired
}


export default LayersListItem;