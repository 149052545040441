import { onShapeDrawnEvent } from '../hooks/EventCatcher';
import { centerToObjectWithAnimation } from './FabricMethods';
import {setObjectsStack, setObjectsStackWithZIndex} from './StackOrder';
import { IMAGE_LOADING_CHUNK_SIZE } from './Constant';
import {generateKeyBetween} from 'fractional-indexing';
import { compressData } from './OptimizationUtils';
import eventEmitter from './EventEmitter';
import { fabric } from 'fabric';
import {LinePolygonUpdater} from './lines/LinePolygonUpdater';
import promiseQueue from '../customClasses/image/PromisePool';
import {attachPolygonsWithCanvasMap} from './lines/LineMethods';

/**
 * Divides a list into chunks of a given size.
 * @param {Array} list The list to be divided into chunks 
 * @param {Number} chunkSize the size of the chunks
 * @returns 
 */
const divideImageListIntoChunks = (list, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < list.length; i += chunkSize) {
        chunks.push(list.slice(i, i + chunkSize));
    }
    return chunks;
}

/**
 * Draws shapes on the canvas.
 * @param {Array} shapes 
 * @param {fabric.Canvas} canvas 
 * @param {Array|[]} stackOrder 
 * @param {boolean} stackOrderUpdated 
 * @param func setTriggerStack 
 * @param {array} activityHistory 
 * @param users
 * @param ownerId
 * @param callback
 * @param callback2
 * @param socketRef
 * @param pageId
 */
const loadShapes = async (shapes, canvas, stackOrder, stackOrderUpdated, setTriggerStack, activityHistory, users = null, ownerId = null, callback, callback2, updatePolygons, socketRef, pageId, lockedShapes) => {
    // zoom to frame if shape param is present
    let zoomToFrameUuid = null;
    try {
        let url_params = new URLSearchParams(window.location.search);
        if (window.self !== window.top) {
            const newUrl = new URL(url_params.get('url'));
            url_params = new URLSearchParams(newUrl.search);
        }
        zoomToFrameUuid = url_params.get('shape');
    } catch (e) {
        console.error('Error happened while loading shapes', e);
    }
    
    const structuredShapes = JSON.parse(JSON.stringify(shapes));
    const framesInSort = structuredShapes.filter(shape => shape.shapeType === 'frame').sort(o => o?.properties?.attachedFrameId ? 1 : -1)
    // first draw frames
    for (const el of framesInSort) {
        try {
            const collabLockedProp = lockedShapes?.find(shape => shape.shapeUUID === el.uuid)
            const frame = await onShapeDrawnEvent(el, canvas, { hideFromActivityLog: true, triggeredBy: 'loadShapes', users, ownerId, collabLockedProp }, activityHistory, true)
            if (zoomToFrameUuid && frame.uuid === zoomToFrameUuid) {
                setTimeout(() => {
                    centerToObjectWithAnimation(canvas, frame, { adaptZoomLevel: true });
                }, 0);
            }
        } catch(err) {
            console.error('error while loading frame', el);
        }
    }

    // divide images into chunks in order to avoid loading too many images at once
    // loading too many images at once causes the chromium browser to not load some of the images
    const images = divideImageListIntoChunks(
        structuredShapes.filter(shape => shape.shapeType === 'optimizedImage'), 
        IMAGE_LOADING_CHUNK_SIZE
    );

    promiseQueue.startCounter(images.length);

    // draw other shapes
    const shapesWithoutFrames = structuredShapes.filter(shape => shape.shapeType !== 'frame');

    // we've started to store polygon uuids instead of the whole polygon objects in lines.
    // update older lines that has polygon as object
    const linePolygonUpdater = new LinePolygonUpdater(canvas, updatePolygons, shapesWithoutFrames.length);
    
    const shapePromises = shapesWithoutFrames.map(el => {
        const collabLockedProp = lockedShapes?.find(shape => shape.shapeUUID === el.uuid)
        return onShapeDrawnEvent(el, canvas, {
            hideFromActivityLog: true,
            triggeredBy: 'loadShapes',
            users,
            ownerId,
            linePolygonUpdater,
            collabLockedProp
        }, activityHistory, true)
    })
    
    const shapePromisesResponse = await Promise.allSettled(shapePromises);
    for (const promiseResult of shapePromisesResponse) {
        if (promiseResult.status !== 'fulfilled') {
            console.error('error while loading shape', promiseResult);
            continue
        }
        
        try {
            const object = promiseResult.value;
            if (object.type === 'group') {
                const textboxObj = object.getObjects().find(obj => obj?.type === 'textbox')
                textboxObj.initDimensions();
            }
            
            // if we can not attach the lines in onShapeDrawnEvent, here we try to attach unattached lines
            if (
                object.type === 'curvedLine' &&
                (
                    (object.leftPolygon && typeof object.leftPolygon === 'string') ||
                    (object.rightPolygon && typeof object.rightPolygon === 'string')
                )
            ) {
                attachPolygonsWithCanvasMap(object, canvas);
            }
        } catch (err) {
            console.error('error while handling process after loading shape', promiseResult.value, err);
        } finally {
            linePolygonUpdater.addedShape()
        }
    }

    // after all shapes are drawn, set the stack order
    if (stackOrder) {
        !stackOrderUpdated ? setObjectsStack(canvas, stackOrder) : setObjectsStackWithZIndex(canvas);
    }

    // Run callback before loading all images
    if (typeof callback === 'function') {
        callback();
    }
 
    // since images are added later, set the stack order again
    if (stackOrder)  {
        !stackOrderUpdated ? setObjectsStack(canvas, stackOrder) : setObjectsStackWithZIndex(canvas);
    }

    if (typeof callback2 === 'function') {
        callback2();
    }
    
    if(!stackOrderUpdated){
        const newObjects = canvas.getObjects();
        let newStackOrder = [];
        let assignedObjects = [];
        
        newObjects.forEach((item)=> {
            let starterIndex = null;
            let enderIndex = null;
            
            let frames = assignedObjects.filter(item => item.shapeType === 'frame');
            let otherShapes = assignedObjects.filter(item => item.shapeType !== 'frame');
            
            if((item.shapeType === 'frame' && frames.length > 0)) {
                starterIndex = frames[0].zIndex;
            }
            
            else if(item.shapeType !== 'frame'){
                //if object have same type of objects on canvas get the first object on canvas as reference
                if(otherShapes.length > 0) starterIndex = otherShapes[0].zIndex;
                //if shape is not frame and have no shapes of same kind on whole canvas then get the latest frame as reference
                else if(frames.length > 0 && otherShapes.length === 0) starterIndex = frames.slice(-1)[0]?.zIndex;
            }
            
            if(starterIndex !== null){
                const _objects = item.shapeType === 'frame' ? frames : otherShapes;
                //need to change this logic. we can't check if object's belong to its own kind or not. If we have group object this is creating issue
                _objects.forEach(item => {
                    if(item?.zIndex > starterIndex) starterIndex = item?.zIndex;
                })
            }
            
            if(item.shapeType === 'frame' && otherShapes.length > 0) {
                let smallestZIndex = otherShapes[0].zIndex;
                otherShapes.forEach(item => {
                    if(item?.zIndex < smallestZIndex) smallestZIndex = item?.zIndex;
                })
                enderIndex = smallestZIndex;
            }
            item.zIndex = generateKeyBetween(starterIndex, enderIndex);
            assignedObjects.push(item);
        })
        
        newStackOrder = assignedObjects.filter(item => item.uuid).map((item) => {
            return {zIndex:item.zIndex, uuid: item.uuid}
        })

        socketRef.current.emit('sendBackFront', compressData({
            stackOrder: newStackOrder,
            pageId
        }));
    }
    
    setObjectsStackWithZIndex(canvas);
    eventEmitter.fire('initalizeStackOrder');
    setTriggerStack(prev => !prev);
}

export default loadShapes;