import clsx from 'clsx';
import { useRef } from 'react'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { FEEDBACK_TEXT_LENGTH_LIMIT } from '../../../helpers/Constant';
import getToastIcon from '../../../helpers/media/GetToastIcon';
import mapFilesWithUuid from '../../../helpers/media/MapFilesWithUuid';
import { filterImageSizes, filterByImageType } from '../../../helpers/media/SizeFilter';

import './FeedBackTextInput.scss';

/**
 * @param {object} props
 * @param props.label
 * @param props.placeholder
 * @param props.style
 * @param props.disabled
 * @param props.images
 * @param props.text
 * @param props.setText
 * @param props.setImages
 */
const FeedBackTextInput = ({
    label,
    placeholder,
    style,
    disabled,
    images: selectedFiles,
    text: value,
    setText: setValue,
    setImages: setSelectedFiles
}) => {
    // const [selectedFiles, setSelectedFiles] = useState([]);
    const attachFileRef = useRef();

    const textOnChangeHandler = (e) => {
        const trimmedVal = e.target.value.trim();
        if (trimmedVal.length > FEEDBACK_TEXT_LENGTH_LIMIT) {
            const newText = e.target.value.trim().substring(0, FEEDBACK_TEXT_LENGTH_LIMIT);
            setValue(newText);
            return;
        }
        setValue(e.target.value);
    }

    const filterFiles = async (filteringFiles) => {
        const baseFiles = filterByImageType(filteringFiles);
        if (baseFiles.length < filteringFiles.length) {
            toast.error('Only image files are allowed to upload.', {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
        }
        const filteredImages = await filterImageSizes(baseFiles, { filterForAttach: true });
        if (filteredImages.isBiggerThanTotalCountLimit) {
            toast.error(`Total file count (${baseFiles.length}) is bigger than allowed total file count (${filteredImages.limitText}). Please try to upload fewer files. `, {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
            return;
        }
        if (filteredImages.isBiggerThanTotalLimit) {
            toast.error(`Allowed total file sizes are bigger than ${filteredImages.limitText}. Please try to upload smaller sized files. `, {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
            return;
        }
        if (filteredImages.unallowedFileCount > 0) {
            toast.error(`${filteredImages.unallowedFileCount} image(s) can't uploaded because their file size is higher than ${filteredImages.limitText}.`, {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
        }
        return filteredImages.filteredFiles;
    }

    const handleFileSelection = async (e) => {
        const oldFiles = selectedFiles?.map(file => file.file) || [];
        const filteredFiles = await filterFiles([...oldFiles, ...e.target.files]);
        if (!filteredFiles || !filteredFiles.length) return;
        const files = mapFilesWithUuid(filteredFiles);

        setSelectedFiles(files);
        attachFileRef.current.value = null;
    }

    const handleRemoveFile = (uuid) => {
        const filteredFiles = selectedFiles?.filter(file => file.uuid !== uuid) || []
        setSelectedFiles(filteredFiles)
    }

    const trimmedVal = typeof value === 'string' ? value.trim() : '';

    return (
        <div className="feedback_modal__text-input-wrapper" style={style ? style : null}>
            <div className="feedback_text_header">
                <h3
                    className="feedback_text_header--title"
                    data-testid="feedback_text_header--title"
                >{label}</h3>
                <span
                    className="feedback_text_header--count"
                    data-testid="feedback_text_header--count">{trimmedVal.length}/{FEEDBACK_TEXT_LENGTH_LIMIT}</span>
            </div>
            <div className="feedback_modal__textInput">
                <textarea
                    className="feedback_modal__textInput--textarea"
                    data-testid="feedback_modal__textInput--textarea"
                    disabled={disabled}
                    onChange={(e) => textOnChangeHandler(e)}
                    placeholder={placeholder ? placeholder : 'Please enter your feedback....'}
                    value={value}
                />
                <button
                    type="button"
                    aria-labelledby="feedback_attachBtn-tooltip"
                    className="feedback_modal__textInput--attachBtn"
                    data-testid="feedback_modal__textInput--attachBtn"
                    disabled={disabled}
                    onClick={() => attachFileRef.current.click()}
                >
                    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" d="M9.29214 0.970139C8.52733 0.764921 7.71401 0.998709 7.16478 1.58165L7.15948 1.58727L1.90013 6.97804C1.90012 6.97805 1.90014 6.97804 1.90013 6.97804C0.533305 8.37922 0.533294 10.651 1.90013 12.0521C3.26697 13.4533 5.48305 13.4533 6.84989 12.0522C6.84988 12.0522 6.84989 12.0522 6.84989 12.0522L13.2531 5.48748C13.424 5.31233 13.701 5.31231 13.8718 5.48745C14.0427 5.66259 14.0427 5.94656 13.8719 6.12171L7.46863 12.6864C5.76009 14.4379 2.98996 14.4379 1.28141 12.6864C-0.427138 10.9349 -0.427138 8.09525 1.28141 6.34378L6.53817 0.955576C7.30705 0.14161 8.44425 -0.184567 9.51371 0.102395C10.5844 0.3897 11.4206 1.2469 11.7009 2.34454C11.9808 3.44085 11.6627 4.6066 10.8687 5.39479L5.60713 10.7915C4.92057 11.4714 3.82904 11.4618 3.1539 10.77C2.47876 10.0781 2.46911 8.95919 3.1322 8.25517L3.13748 8.24957L8.39685 2.85639C8.56767 2.68121 8.84468 2.68116 9.01557 2.85628C9.18645 3.0314 9.18649 3.31537 9.01567 3.49054L3.75929 8.88075C3.43015 9.2329 3.43576 9.7905 3.77252 10.1356C4.10928 10.4807 4.65321 10.4863 4.99663 10.1488L10.2579 4.75241C10.8265 4.18939 11.0546 3.35569 10.8544 2.57167C10.6542 1.78765 10.057 1.17536 9.29214 0.970139Z" fill="#6200EA" fillRule="evenodd"/>
                    </svg>
                    <span className="tooltipText" id='feedback_attachBtn-tooltip'>Attach File</span>
                </button>
                <input
                    accept="image/*"
                    aria-hidden
                    className="feedback_modal__textInput--attachFile"
                    data-testid="feedback_modal__textInput--attachFile"
                    disabled={disabled}
                    multiple
                    onChange={handleFileSelection}
                    ref={attachFileRef}
                    type='file'
                />
            </div>
            <div className="feedback_modal__files" data-testid="feedback_modal__files">
                {selectedFiles?.length > 0 ? selectedFiles.map(item => (
                    <div className={clsx('feedback_modal__files--item', {
                        'disabled': disabled
                    })} key={item?.uuid}>
                        <span
                            className="feedback_modal__files--item-name"
                            data-testid="feedback_modal__files--item-name">{item?.file?.name}</span>
                        <button
                            type="button"
                            className="feedback_modal__files--item-remove"
                            data-testid="feedback_modal__files--item-remove"
                            disabled={disabled}
                            onClick={() => handleRemoveFile(item?.uuid)}
                        >
                            <em aria-hidden className="icon-close-circle" />
                        </button>
                    </div>
                )) : null}
            </div>
        </div>
    )
}

FeedBackTextInput.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]),
    placeholder: PropTypes.string,
    style: PropTypes.object ,
    disabled: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.object),
    text: PropTypes.string,
    setText: PropTypes.func,
    setImages: PropTypes.func
}

export default FeedBackTextInput;