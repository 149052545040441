export const environment = {
    REACT_APP_API_ENDPOINT: window['env'].REACT_APP_API_ENDPOINT,
    NOW_API_ENDPOINT: 'https://api-now.engineer.ai',
    REACT_APP_AUTH_TOKEN: window['env'].REACT_APP_AUTH_TOKEN,
    envType: window['env'].envType,
    USER_COOKIE: window['env'].USER_COOKIE,
    NOW_BASE_URL: 'https://now.builder.ai',
    MAINTENANCE: window['env'].MAINTENANCE,
    ENTERPRISE_AUTH_TOKEN: window['env'].ENTERPRISE_AUTH_TOKEN,
    NIMA_BOARD_CLIENT_ID: '808aab85aeb95397b9251e32e19a9881'
}