import { 
    drawHorizontalLine, 
    drawVerticalLine, 
    generateHorizontalCoordinates, 
    generateVerticalCoordinates, 
    getBoundingCoordinates, 
    getHeightDifferenceBetweenOrigin, 
    getProximityDistance, 
    getWidthDifferenceBetweenOrigin, 
    isInRange, 
    sortHorizontalLinesByProximity, 
    sortVerticalLinesByProximity
} from './Utils';
import { isLinePointInsideOfObject } from '../FabricMethods';

/**
 * @param {fabric.Canvas} canvas 
 * @param {fabric.Object} object - The object that is wanted to be aligned.
 * @param {CanvasRenderingContext2D} ctx Context of the top layer of the canvas 
 * @param options
 * @returns 
 */
export function generateAlignmentLines(canvas, object, ctx, options = {}) {
    const zoom = canvas.getZoom();
    const movingObjectBoundingRect = getBoundingCoordinates(object)

    let isInsideOfAnyObject = false;
  
    const canvasObjects = canvas
        .getObjects()
        .filter(
            (item) => {
                const shouldInclude = item.shapeType &&
        item.shapeType !== 'frame' &&
        item.shapeType !== 'highlight' &&
        item.shapeType !== 'curvedLine' &&
        item.type !== 'comment' &&
        item.isOnScreen() &&
        item.type !== 'path' &&
        item.shapeType !== 'magnetCircle' &&
        item.shapeType !== 'mockFrame' &&
        item.shapeType !== 'highlight' &&
        item.shapeType !== 'duplicateShadowObj' &&
        item.shapeType !== 'duplicateShadowObjGroup' &&
        item !== object && item.visible;

                if (shouldInclude && options?.isLine) {
                    // if the object is a line, check if the line is inside of the any object
                    // if its, we don't 
                    try {
                        const lineCoords = getBoundingCoordinates(object);
                        const itemCoords = getBoundingCoordinates(item, 5 / zoom);
                        const isInside = isLinePointInsideOfObject(
                            itemCoords,
                            lineCoords,
                        );
                        if (isInside) {
                            isInsideOfAnyObject = true;
                            return false;
                        }
                    } catch (err) {
                        console.error('Error happened', err);
                    }

                }

                return shouldInclude;
            }
        
        );
    // if the moving object is line and it is inside of any object, don't generate alignment lines
    if (options?.isLine && isInsideOfAnyObject) return { verticalLines: [], horizontalLines: [] };
    
    let verticalLines = [], horizontalLines = [];
  
    for (const nearObject of canvasObjects) {
        const nearObjectBoundingRect = getBoundingCoordinates(nearObject);
        const proximity = getProximityDistance(movingObjectBoundingRect, nearObjectBoundingRect);
      
        // vertical ranges
        const verticalLeftLeftRange = isInRange(movingObjectBoundingRect.tl.x, nearObjectBoundingRect.tl.x, zoom);
        const verticalRightRightRange = isInRange(movingObjectBoundingRect.tr.x, nearObjectBoundingRect.tr.x, zoom);
        const verticalCenterCenterRange = !options.isLine ? isInRange(movingObjectBoundingRect.center.x, nearObjectBoundingRect.center.x, zoom) : { isInRange: false };
        const verticalLeftRightRange = isInRange(movingObjectBoundingRect.tl.x, nearObjectBoundingRect.tr.x, zoom);
        const verticalRightLeftRange = isInRange(movingObjectBoundingRect.tr.x, nearObjectBoundingRect.tl.x, zoom);
  
        // horizontal ranges
        const horizontalTopTopRange = isInRange(movingObjectBoundingRect.tl.y, nearObjectBoundingRect.tl.y, zoom);
        const horizontalBottomBottomRange = isInRange(movingObjectBoundingRect.bl.y, nearObjectBoundingRect.bl.y, zoom);
        const horizontalCenterCenterRange = !options.isLine ? isInRange(movingObjectBoundingRect.center.y, nearObjectBoundingRect.center.y, zoom) : { isInRange: false };
        const horizontalTopBottomRange = isInRange(movingObjectBoundingRect.tl.y, nearObjectBoundingRect.bl.y, zoom);
        const horizontalBottomTopRange = isInRange(movingObjectBoundingRect.bl.y, nearObjectBoundingRect.tl.y, zoom);
      
  
        if (verticalLeftLeftRange.isInRange) {
            verticalLines.push({
                x: nearObjectBoundingRect.tl.x,
                ...generateVerticalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.tl.x + getWidthDifferenceBetweenOrigin(object, movingObjectBoundingRect.width, 'left'),
                proximity,
                verticalLinePosition: 'left',
                range: verticalLeftLeftRange.range
            });
        }
        if (verticalRightRightRange.isInRange) {
            verticalLines.push({
                x: nearObjectBoundingRect.tr.x,
                ...generateVerticalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.tr.x - getWidthDifferenceBetweenOrigin(object, movingObjectBoundingRect.width, 'right'),
                proximity,
                verticalLinePosition: 'right',
                range: verticalRightRightRange.range
            });
        }
        if (verticalCenterCenterRange.isInRange) {
            verticalLines.push({
                x: nearObjectBoundingRect.center.x,
                ...generateVerticalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.center.x + getWidthDifferenceBetweenOrigin(object, movingObjectBoundingRect.width, 'center'),
                proximity,
                verticalLinePosition: 'center',
                range: verticalCenterCenterRange.range
            });
        }
        if (verticalLeftRightRange.isInRange) {
            verticalLines.push({
                x: nearObjectBoundingRect.tr.x,
                ...generateVerticalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.tr.x + getWidthDifferenceBetweenOrigin(object, movingObjectBoundingRect.width, 'left'),
                proximity,
                verticalLinePosition: 'left',
                range: verticalLeftRightRange.range
            });
        }
        if (verticalRightLeftRange.isInRange) {
            verticalLines.push({
                x: nearObjectBoundingRect.tl.x,
                ...generateVerticalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.tl.x - getWidthDifferenceBetweenOrigin(object, movingObjectBoundingRect.width, 'right'),
                proximity,
                verticalLinePosition: 'right',
                range: verticalRightLeftRange.range
            });
        }
  
        if (horizontalTopTopRange.isInRange) {
            horizontalLines.push({
                y: nearObjectBoundingRect.tl.y,
                ...generateHorizontalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.tl.y + getHeightDifferenceBetweenOrigin(object, movingObjectBoundingRect.height, 'top'),
                proximity,
                horizontalLinePosition: 'top',
                range: horizontalTopTopRange.range
            });
        }
        if (horizontalBottomBottomRange.isInRange) {
            horizontalLines.push({
                y: nearObjectBoundingRect.bl.y,
                ...generateHorizontalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.bl.y - getHeightDifferenceBetweenOrigin(object, movingObjectBoundingRect.height, 'bottom'),
                proximity,
                horizontalLinePosition: 'bottom',
                range: horizontalBottomBottomRange.range
            });
        }
        if (horizontalCenterCenterRange.isInRange) {
            horizontalLines.push({
                y: nearObjectBoundingRect.center.y,
                ...generateHorizontalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.center.y + getHeightDifferenceBetweenOrigin(object, movingObjectBoundingRect.height, 'center'),
                proximity,
                horizontalLinePosition: 'center',
                range: horizontalCenterCenterRange.range
            }); 
        }
        if (horizontalTopBottomRange.isInRange) {
            horizontalLines.push({
                y: nearObjectBoundingRect.bl.y,
                ...generateHorizontalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.bl.y + getHeightDifferenceBetweenOrigin(object, movingObjectBoundingRect.height, 'top'),
                proximity,
                horizontalLinePosition: 'top',
                range: horizontalTopBottomRange.range
            });  
        }
        if (horizontalBottomTopRange.isInRange) {
            horizontalLines.push({
                y: nearObjectBoundingRect.tl.y,
                ...generateHorizontalCoordinates(
                    movingObjectBoundingRect,
                    nearObjectBoundingRect
                ),
                applyingPos: nearObjectBoundingRect.tl.y - getHeightDifferenceBetweenOrigin(object, movingObjectBoundingRect.height, 'bottom'),
                proximity,
                horizontalLinePosition: 'bottom',
                range: horizontalBottomTopRange.range
            });  
        }
    }
  
    if (verticalLines.length) {
        verticalLines = sortVerticalLinesByProximity(verticalLines);
    }
  
    if (horizontalLines.length) {
        horizontalLines = sortHorizontalLinesByProximity(horizontalLines);
    }
  
    for (const verticalLine of verticalLines) {
        drawVerticalLine(verticalLine, ctx, canvas);
    }
    for (const horizontalLine of horizontalLines) {
        drawHorizontalLine(horizontalLine, ctx, canvas)
    }
  
    return {
        verticalLines,
        horizontalLines
    }
}

  
export const lineAlignmentHandler = (line, pointIndex, position) => {
    try {
        const object = {
            left: line.points[pointIndex].x,
            top: line.points[pointIndex].y,
            originX: 'center',
            originY: 'center',
            width: 0,
            height: 0,
            getBoundingRect: () => {
                return {
                    left: line.points[pointIndex].x,
                    top: line.points[pointIndex].y,
                    width: 0,
                    height: 0
                }
            },
            getScaledWidth: () => 0,
            getScaledHeight: () => 0,
        }
        const canvas = line.canvas;
        const ctx = canvas.getSelectionContext();
        const zoom = canvas.getZoom();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let setLeft = false, setTop = false, snapLeft, snapTop;
        const {
            verticalLines,
            horizontalLines
        } = generateAlignmentLines(canvas, object, ctx, { isLine: true });
        if (verticalLines.length) {
            snapLeft = verticalLines[0].applyingPos;
        }
        if (horizontalLines.length) {
            snapTop = horizontalLines[0].applyingPos;
        }
    
        if (isInRange(position.x, snapLeft, zoom).isInRange) {
            position.x = snapLeft;
            setLeft = true;
        }
        if (isInRange(position.y, snapTop, zoom).isInRange) {
            position.y = snapTop;
            setTop = true;
        }
  
        if (setLeft) {
            line.points[pointIndex].x = snapLeft;
        }
        if (setTop) {
            line.points[pointIndex].y = snapTop;
        }
    } catch (error) {
        console.error('error in lineAlignmentHandler', error);
    }

}