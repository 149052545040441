import { createSlice } from '@reduxjs/toolkit';

const layersReducer = createSlice({
    name: 'layers',
    initialState: {
        defaultLayerId: null,
        list: [],
        pageLevelLayersShapesCount: {},
        layersShapesVisibility: {},
    },
    reducers: {
        initLayers(state, action) {
            state.defaultLayerId = action.payload.defaultLayerId;
            state.list = action.payload.layers;
        },
        add(state, action) {
            state.list.push(action.payload);
        },
        changeName(state, action) {
            const updatingLayerData = action.payload;
            state.list.map((layer) => {
                if (layer.uuid === updatingLayerData?.uuid) {
                    layer.name = updatingLayerData.name;
                }
                return layer;
            });
        },
        addLayersShapesCountAndVisibility(state, action) {
            state.pageLevelLayersShapesCount = {
                ...state.pageLevelLayersShapesCount,
                ...(action.payload.pageLevelLayersShapesCount ?? {}),
            };
            state.layersShapesVisibility = {
                ...state.layersShapesVisibility,
                ...(action.payload.layersShapesVisibility ?? {}),
            };
        },
        updateLayersShapesCountAndVisibility(state, action) {
            const pageId = action.payload.pageId;
            if (!state.pageLevelLayersShapesCount[pageId]) {
                state.pageLevelLayersShapesCount[pageId] = {};
            }
            let pageLayersCountData = state.pageLevelLayersShapesCount[pageId];
            let layersShapesVisibility = state.layersShapesVisibility;
            const updateLayers = action.payload.updateLayers;
            Object.keys(updateLayers).forEach((layerId) => {
                const addedOrRemovedShapeLayerCount = updateLayers[layerId].count ?? 0;
                const prevCount = pageLayersCountData[layerId]?.count ?? 0;
                pageLayersCountData[layerId] = { count: prevCount + addedOrRemovedShapeLayerCount };
                const visibility = updateLayers[layerId].visibility ?? layersShapesVisibility[layerId]?.visibility;
                layersShapesVisibility[layerId] = { visibility };
            });
        },

        clearState(state) {
            state.defaultLayerId = null;
            state.list = [];
            state.layersShapesVisibility = {};
            state.pageLevelLayersShapesCount = {};
        },
    },
});

export default layersReducer.reducer;
