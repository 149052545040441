/* eslint-disable */
import {level2} from "./nimaRectangle";

const nimaStickyNoteValues = {
    margine: 4,
    enTypography: {
        font: "Open Sans",
        dir: "ltr"
    },
    faTypography: {
        font: "Iran Sans",
        dir: "rtl"
    }
}

const fontSizeHelper = {
    defaultSize: 35,
    minFontSize: 5
}

const calcFontSize = (e, t, n, o) => {
    var a = fontSizeHelper.defaultSize * o
        , r = fontSizeHelper.minFontSize * o
        , s = 0
        , l = []
        , c = document.createElement("canvas");
    c.height = n.h,
        c.width = n.w;
    var d = c.getContext("2d")
        , p = t => {
            var o = [];
            for (var a of e.split("\n"))
                t(d, a, n.w).map((e => o.push(e)));
            return o
        }
        , u = e => {
            if ((l = p(e)).length * i.A.getLineHeight(s) > n.h)
                return !1;
            for (var t of l) {
                if (d.measureText(t).width > n.w)
                    return !1
            }
            return !0
        }
    ;
    for (s = a; s > r && (d.font = s + "px " + t,
        !u(i.A.simpleWrap)); )
        s--;
    if (s < 15)
        for (s = a; s > r; ) {
            if (d.font = s + "px " + t,
                l = p(i.A.wrap),
            i.A.getLineHeight(s) * l.length <= n.h)
                break;
            s--
        }
    return {
        fontSize: s
    }
}

export class NimaStickyNote extends level2 {
    constructor(e, t, n, o, a, r, i) {
        if (super(),
            this.text = e,
            this.x = t,
            this.y = n,
            this.color = o,
            this.fontSize = a,
            this.cmd = "stickyNote",
        null == r) {
            var s = NimaStickyNote.getSize("M");
            this.w = s.w,
                this.h = s.h
        } else
            this.w = r,
                this.h = i;
        this._cached = {}
    }
    set font(e) {
        this._cached = {},
            this._font = e
    }
    get font() {
        return this._font
    }
    set fontSize(e) {
        this._cached = {},
            this._fontSize = e
    }
    get fontSize() {
        return this._fontSize
    }
    set text(e) {
        this._cached = {},
            this._text = e
    }
    get text() {
        return this._text
    }
    getAspectRatio(e) {
        return "boolean" == typeof e && e ? "preserved" : "free"
    }
    updateSize(e, t, n, o) {
        this.updatePosition(e, t),
            this.w = n,
            this.h = o
    }
    getBoundingRect() {
        return {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h
        }
    }
    static getContentRect(e, t) {
        return {
            w: e - 2 * nimaStickyNoteValues.margine,
            h: t - 2 * nimaStickyNoteValues.margine
        }
    }
    getUpdatedContent(e, t) {
        var n = NimaStickyNote.getContentRect(e, t);
        return {
            fontSize: r.A.calcFontSize(this.text, this.getTypography().font, {
                x: 0,
                y: 0,
                w: n.w,
                h: n.h
            }, 1).fontSize
        }
    }
    contains(e, t) {
        return e >= this.x && e <= this.x + this.w && t + 3 >= this.y && t + 3 <= this.y + this.h
    }
    static getSize(e) {
        var t = {
            S: {
                w: 100,
                h: 100
            },
            M: {
                w: 170,
                h: 170
            },
            L: {
                w: 270,
                h: 270
            },
            SW: {
                w: 200,
                h: 100
            },
            MW: {
                w: 340,
                h: 170
            },
            LW: {
                w: 540,
                h: 270
            }
        };
        if ("string" == typeof e)
            return t[e];
        for (var n in t)
            if (t[n].w === e.w && t[n].h == t[n].h)
                return n
    }
    static getTypographyByText(e) {
        return a.A.isRTL(e) ? nimaStickyNoteValues.faTypography : nimaStickyNoteValues.enTypography
    }
    getTypography() {
        return this._isRTL() ? nimaStickyNoteValues.faTypography : nimaStickyNoteValues.enTypography
    }
    static getLineHeight(e) {
        return 130 * e / 100
    }
    static simpleWrap(e, t, n) {
        for (var o = [], a = "", r = t.split(" "), i = 0; i < r.length; i++)
            e.measureText(a + " " + r[i]).width > n ? (o.push(a),
                a = r[i]) : a.length < 1 ? a += r[i] : a += " " + r[i];
        return o.push(a),
            o
    }
    static wrap(e, t, n) {
        for (var o = [], a = "", r = -1, i = 0; i < t.length; i++)
            " " === t.charAt(i) && (r = a.length),
            "-" === t.charAt(i) && (r = a.length + 1),
                e.measureText(a + t.charAt(i)).width > n ? (r > -1 ? (o.push(a.substring(0, r)),
                    a = (a.substring(r) + t.charAt(i)).trimStart()) : (o.push(a),
                    a = t.charAt(i)),
                    r = -1) : a += t.charAt(i);
        return o.push(a),
            o.map((t => ({
                l: t,
                w: e.measureText(t).width
            })))
    }
    getText() {
        return this.text
    }
    _isRTL() {
        return void 0 === this._cached.dir && (this._cached.dir = a.A.isRTL(this.text)),
            this._cached.dir
    }
    draw(e) {
        var t = this.boardContext ? this.boardContext.scale : 1;
        e.shadowBlur = 8,
            e.shadowColor = "#C5C6C5",
            e.shadowOffsetX = 3 * t,
            e.shadowOffsetY = 3 * t,
            e.fillStyle = this.color,
            e.roundRect(this.x, this.y, this.w, this.h, 4),
            e.fill(),
            e.shadowBlur = 0,
            e.shadowOffsetX = 0,
            e.shadowOffsetY = 0,
            e.fillStyle = "#101010",
            e.textBaseline = "top",
            e.textAlign = "left";
        var n = this.getTypography();
        e.direction = n.dir,
            e.font = this.fontSize + "px " + n.font;
        var o = nimaStickyNoteValues.margine
            , a = this.w - 2 * o
            , r = this.h - 2 * o
            , s = this.y + o
            , c = this.x + o
            , d = [];
        if (this._cached.lines)
            d = this._cached.lines;
        else {
            for (var p of this.text.split("\n"))
                NimaStickyNote.wrap(e, p, a).map((e => d.push(e)));
            this._cached.lines = d
        }
        var u = NimaStickyNote.getLineHeight(this.fontSize)
            , h = u * d.length
            , m = s + Math.round(r / 2 - h / 2)
            , f = !!this.boardContext && this.boardContext.isBlurry(this);
        for (var g of d) {
            if (f)
                (new i.A).drawWireframeText(e, g.l, c + Math.round(a / 2 - g.w / 2), m, this.fontSize, "#101010");
            else
                e.fillText(g.l, c + Math.round(a / 2 - g.w / 2), m);
            m += u
        }
    }
    toJSON() {
        return super.toJSON({
            cmd: "stickyNote",
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            color: this.color,
            fontSize: this.fontSize,
            text: this.text
        })
    }
    clone() {
        return super.addMetadata(new NimaStickyNote(this.text,this.x,this.y,this.color,this.fontSize,this.w,this.h), this)
    }
    clone2() {
        return super.clone2(new NimaStickyNote(this.text,this.x,this.y,this.color,this.fontSize,this.w,this.h))
    }
}