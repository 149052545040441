export const IconLayer = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 22 20' fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.0917 5.79418L11.5833 1.39335C11.2132 1.20222 10.7734 1.20222 10.4033 1.39335L1.89666 5.79418C1.72582 5.88198 1.61841 6.05794 1.61841 6.25002C1.61841 6.4421 1.72582 6.61806 1.89666 6.70585L10.4042 11.1067C10.7743 11.2978 11.214 11.2978 11.5842 11.1067L20.0917 6.70585C20.262 6.61764 20.3689 6.44183 20.3689 6.25002C20.3689 6.0582 20.262 5.88239 20.0917 5.79418Z'
                stroke='#262626'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M1.63159 10.625L10.4041 14.8567C10.7742 15.0478 11.214 15.0478 11.5841 14.8567L20.3816 10.625'
                stroke='#262626'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M1.63159 14.375L10.4041 18.6067C10.7742 18.7978 11.214 18.7978 11.5841 18.6067L20.3816 14.375'
                stroke='#262626'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
