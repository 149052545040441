import { v4 as uuidv4} from 'uuid';
import { SIZE_LIMIT } from '../Constant';
import { calculateImageMP, getImageDimensions } from './ImageHelper';

export const filterImageSizes = async (files, options = {}) => {
    if (options.filterForAttach && files.length > SIZE_LIMIT.attachTotalImageLimit) {
        return {
            limitText: SIZE_LIMIT.attachTotalImageLimit,
            isBiggerThanTotalCountLimit: true
        }
    }
    if (files.length > SIZE_LIMIT.totalImageLimit) {
        return {
            limitText: SIZE_LIMIT.totalImageLimit,
            isBiggerThanTotalCountLimit: true
        }
    }
    const filteredFiles = [];
    let unallowedFileCount = 0;
    for (const file of files) {
        if (file.size > SIZE_LIMIT.limit) {
            unallowedFileCount++;
        } else {
            filteredFiles.push(file);
        }
    }

    let higherMpFileCount = 0;
    for (const file of filteredFiles) {
        const { width, height } = await getImageDimensions(file);
        const mp = calculateImageMP(width, height);

        if (mp > SIZE_LIMIT.mpLimit) {
            higherMpFileCount += 1;
        }
    }

    let size = 0;
    for (const file of filteredFiles) {
        size += file.size;
    }
    if (size > SIZE_LIMIT.totalSizeLimit) {
        return {
            limitText: SIZE_LIMIT.totalSizeLimitText,
            isBiggerThanTotalLimit: true
        }
    }
    return {
        unallowedFileCount,
        higherMpFileCount,
        limitText: SIZE_LIMIT.limitText,
        filteredFiles
    }
}


// separate files to multiple arrays if total size of each array is bigger than limit
export const separateFilesForSizeLimit = (files) => {
    const SIZE_LIMIT = 20 * 1024 * 1024;
    const TOTAL_COUNT_LIMIT_PER_ROW = 20;
    const separatedFiles = {};

    let size = 0,
        rowCount = 0,
        fileCount = 0;
    for (const fileWrap of files) {
        fileCount += 1;
        size += fileWrap.file.size;
        if (size > SIZE_LIMIT || fileCount % TOTAL_COUNT_LIMIT_PER_ROW === 0) {
            rowCount++;
            size = 0;
        }
        if (!separatedFiles[rowCount]) {
            separatedFiles[rowCount] = {
                files: [],
                rowUuid: uuidv4()
            }
        }
        separatedFiles[rowCount].files.push(fileWrap);
    }
    return separatedFiles;
}
export const filterByImageType = (files) => {
    const baseFiles = [];
    for (const file of files) {
        if (file.type.includes('image')) {
            baseFiles.push(file);
        }
    }
    return baseFiles;
}