import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import './LayersList.scss';
import LayersListItem from '../layersListItem/LayersListItem';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const LayersList = ({ userAccessRef, socketRef }) => {
    const layers = useSelector((state) => state?.layers?.list);

    return (
        <Scrollbar trackVisible>
            <div className='layers_widget_list'>
                {layers.map((layer, idx) => (
                    <React.Fragment key={layer.uuid}>
                        <LayersListItem userAccessRef={userAccessRef} layer={layer} socketRef={socketRef} />
                        {idx === layers?.length - 1 ? null : <div className='line-separator'></div>}
                    </React.Fragment>
                ))}
            </div>
        </Scrollbar>
    );
};

LayersList.propTypes = {
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
    }).isRequired,
    socketRef: PropTypes.shape({
        current: PropTypes.object,
    }).isRequired,
};

export default LayersList;
