/* eslint-disable */
import {level2} from "./nimaRectangle";

export class NimaComposite extends level2 {
    constructor(e) {
        super(),
            this.cmd = "composite",
            this.shapes = e
    }
    getAspectRatio(e) {
        return "preserved"
    }
    updateSize(e, t, n, o) {
        var a = this.getBoundingRect()
            , r = a.x - e
            , i = a.y - t
            , s = n / a.w
            , l = o / a.h;
        for (var c of this.shapes) {
            var d = c.getBoundingRect();
            c.updateSize(d.x - r, d.y - i, d.w * s, d.h * l);
            var p = d.x - e - r
                , u = d.y - t - i;
            c.updatePosition(e + p * s, t + u * l)
        }
    }
    updatePosition(e, t) {
        var n = this.getBoundingRect()
            , o = n.x - e
            , a = n.y - t;
        for (var r of this.shapes) {
            var i = r.getBoundingRect();
            r.updatePosition(i.x - o, i.y - a)
        }
    }
    getBoundingRect(e) {
        if (this.shapes.length < 1)
            return {
                x: 0,
                y: 0,
                w: 0,
                h: 0
            };
        var t = Number.MAX_SAFE_INTEGER
            , n = Number.MAX_SAFE_INTEGER
            , o = Number.MIN_SAFE_INTEGER
            , a = Number.MIN_SAFE_INTEGER;
        for (var r of this.shapes) {
            var i = r.getBoundingRect(e);
            t = Math.min(i.x, t),
                n = Math.min(i.y, n),
                o = Math.max(i.x + i.w, o),
                a = Math.max(i.y + i.h, a)
        }
        return {
            x: t,
            y: n,
            w: o - t,
            h: a - n
        }
    }
    contains(e, t) {
        var n = this.getBoundingRect();
        return e >= n.x && t >= n.y && e <= n.x + n.w && t <= n.y + n.h
    }
    draw(e) {
        for (var t of this.shapes)
            t.draw(e)
    }
    toJSON() {
        return super.toJSON({
            cmd: "composite",
            shapes: this.shapes.map((e => e.toJSON()))
        })
    }
    clone() {
        return new NimaComposite(this.shapes.map((e => e.clone())))
    }
    clone2() {
        return super.clone2(new NimaComposite(this.shapes.map((e => e.clone2()))))
    }
}