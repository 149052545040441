
/**
 * Get Image Dimensions
 * @param {File} image
 * @returns {{ width: number, height: number }}
 */
export const getImageDimensions = async (image) => {
    const imageBitmap = await createImageBitmap(image);
    const width = imageBitmap.width;
    const height = imageBitmap.height;

    return { width, height }
}

/**
 * Calculating megapixel(MP) for an image.
 * @param {number} width 
 * @param {number} height 
 * @returns {number}
 */
export const calculateImageMP = (width, height) => {
    return (width * height) / (1000000);
}