import PropTypes from 'prop-types';

const IconEye = ({
    closed = false,
    width = 21,
    height = 20
}) => {
    
    if (closed) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 21 20"
                style={{ width, height }}
            >
                <path
                    stroke="#3C3E49"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="m2.818 17.5 15.625-15M7.97 15.876a8.3 8.3 0 0 0 2.53.374c3.416.057 6.881-2.344 9.02-4.698a1.386 1.386 0 0 0 0-1.855A17 17 0 0 0 16.958 7.4M12.51 5.235A8 8 0 0 0 10.5 5c-3.36-.056-6.834 2.293-9.018 4.696a1.386 1.386 0 0 0 0 1.855 17.3 17.3 0 0 0 2.167 2"
                ></path>
                <path
                    stroke="#3C3E49"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M7.375 10.625A3.124 3.124 0 0 1 10.5 7.5M13.625 10.624A3.125 3.125 0 0 1 10.5 13.75"
                ></path>
            </svg>
        )
    }
    
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 21 20"
            style={{ width, height }}
        >
            <path
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10.5 4.376c-3.36-.057-6.834 2.29-9.018 4.695a1.386 1.386 0 0 0 0 1.855c2.137 2.353 5.601 4.755 9.018 4.698 3.416.057 6.881-2.345 9.02-4.698a1.386 1.386 0 0 0 0-1.855c-2.187-2.404-5.661-4.752-9.02-4.695"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M13.625 10A3.125 3.125 0 1 1 7.375 10a3.125 3.125 0 0 1 6.25.001"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

IconEye.propTypes = {
    closed: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default IconEye;
