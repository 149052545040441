// eslint-disable-next-line
export const mentionPattern = /\B@\[([\w\s\.]+)\]\((\d+)\)/g
// eslint-disable-next-line
export const oldMentionPatternFor3PMigration = /@\[([\w\s\.]+)\]\((\d+)\)/g
export const newMentionPattern = /@([\w.]+)/g
export const newMentionPatternFor3PMigration = /@([\w.]+)@/g

/**
 * @param str
 * @param replacement
 * @param index
 * @param length
 */
export function replaceAt(
    str,
    replacement,
    index,
    length = 0
) {
    const prefix = str.substr(0, index);
    const suffix = str.substr(index + length);
  
    return prefix + replacement + suffix;
}

/**
 * Returns active word.
 * @param input
 * @param taggedUsers
 * @param cursorPosition
 */
export function getActiveWord(input, taggedUsers, cursorPosition) {
    let content = input.split(/[\s\n]/);
    
    if(Array.isArray(taggedUsers) && taggedUsers.length > 0){
        for (const element of taggedUsers) {
            content.splice(element.index, 1, `@${element.id}@`);
        }
    }
    
    content = content.filter(item => item !== null).join(' ');
    
    const actualCursorPosition = cursorPosition - input.length + content.length
    
    const newTokenizedQuery = content.split(/[\s\n]/).reduce((acc, word, index) => {
        const previous = acc[index - 1];
        const start = index === 0 ? index : previous.range[1] + 1;
        const end = start + word.length;
        
        return acc.concat([{ word, range: [start, end], index }]);
    }, []);
    
    const newActiveWord = newTokenizedQuery.find(
        ({ range }) => range[0] < actualCursorPosition && range[1] >= actualCursorPosition
    );
    
    const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
        const previous = acc[index - 1];
        const start = index === 0 ? index : previous.range[1] + 1;
        const end = start + word.length;
  
        return acc.concat([{ word, range: [start, end], index }]);
    }, []);
  
    if (cursorPosition === undefined || actualCursorPosition === undefined) {
        return undefined;
    }
  
    const activeWord = tokenizedQuery.find(
        ({ range }) => range[0] < cursorPosition && range[1] >= cursorPosition
    );
  
    return {activeWord, newActiveWord};
}

/**
 * Renders comments content as html (wraps all mentions with span element).
 * @param {str} content 
 */
export function renderCommentContent(content) {
    return content.replaceAll(
        mentionPattern,
        "<span class='commentItem__comment--mention' data-user='$2'>@$1</span>"
    )
}
  

/**
 * @param content
 */
export function getPlainContent(content) {
    return content.replaceAll(
        mentionPattern,
        '@$1'
    )
}

/**
 * @param newContent
 * @param users
 */
export function replaceMentions(content, users) {
    const usersArray = [...users];
    usersArray.sort((a, b) => a.newRange[0] - b.newRange[0]);
    
    let updatedContent = content;
    let offset = 0; // To account for changes in string length when replacing
    
    // Loop over each tagged user
    usersArray.forEach(user => {
        const { id, commentUsername, newRange } = user;
        
        // Extract the start and end of the range
        const rangeStart = newRange[0] + offset;
        const rangeEnd = newRange[1] + offset;
        
        // The portion of content that should be replaced
        const taggedPortion = updatedContent.substring(rangeStart, rangeEnd);
        
        // Only replace if the exact match is found
        if (taggedPortion === `@${id}@`) {
            const replacement = `<span class='commentItem__comment--mention' data-user='${id}'>@${commentUsername}</span>`;
            
            // Replace the tag with the span
            updatedContent =
                updatedContent.slice(0, rangeStart) +
                replacement +
                updatedContent.slice(rangeEnd);
            
            // Adjust the offset for future replacements (because the new span is longer than the original @id@)
            offset += replacement.length - taggedPortion.length;
        }
    });
    
    return updatedContent;
}