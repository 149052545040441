import PropTypes from 'prop-types';

const IconTick2 = ({
    width = 14,
    height = 14,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 14 14"
        style={{ width, height }}
    >
        <path
            stroke="#6200EA"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m2.917 7 2.304 3.325a.612.612 0 0 0 .996.03l5.741-7.438"
        ></path>
    </svg>
);

IconTick2.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}

export default IconTick2;