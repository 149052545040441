/* eslint-disable */

import {level2} from "./nimaRectangle";
import {NimaLine} from "./nimaLine";

export class NimaPen extends level2 {
    constructor(e, t, n, o) {
        super(),
            this.points = e,
            this.color = t,
            this.lineWidth = n,
            this.cmd = "pen",
        void 0 === o && (o = "pen"),
            this.type = o
    }
    getThickness() {
        return this.lineWidth / 2
    }
    updateSize(e, t, n, o) {
        var a = this.getBoundingRect()
            , r = n / a.w
            , i = o / a.h;
        for (var s of this.points)
            s.x = s.x * r,
                s.y = s.y * i;
        this.updatePosition(e, t)
    }
    updatePosition(e, t) {
        var n = this.getBoundingRect();
        for (var o of this.points)
            o.x = o.x - (n.x - e),
                o.y = o.y - (n.y - t)
    }
    getBoundingRect() {
        var e = this.getThickness()
            , t = Math.min(...this.points.map((e => e.x)))
            , n = Math.min(...this.points.map((e => e.y)))
            , o = Math.max(...this.points.map((e => e.x)))
            , a = Math.max(...this.points.map((e => e.y)));
        return {
            x: t - e,
            y: n - e,
            w: o - t + this.lineWidth,
            h: a - n + this.lineWidth
        }
    }
    contains(e, t) {
        for (var n = 0; n < this.points.length - 1; n++) {
            var o = [];
            if (o.push(this.points[n]),
                o.push(this.points[n + 1]),
                new NimaLine(o,this.color,this.lineWidth).contains(e, t))
                return !0
        }
        return !1
    }
    midPointBtw(e, t) {
        return {
            x: e.x + (t.x - e.x) / 2,
            y: e.y + (t.y - e.y) / 2
        }
    }
    draw(e) {
        super.setStyle(e),
        "hl" === this.type && (e.strokeStyle = e.strokeStyle + oS.opacity),
            e.beginPath();
        var t = this.points[0]
            , n = this.points[1];
        e.moveTo(t.x, t.y);
        for (var o = 1; o < this.points.length; o++) {
            var a = this.midPointBtw(t, n);
            e.quadraticCurveTo(t.x, t.y, a.x, a.y),
                t = this.points[o],
                n = this.points[o + 1]
        }
        e.lineTo(t.x, t.y),
            e.stroke()
    }
    toJSON() {
        return super.toJSON({
            cmd: "pen",
            points: this.points.map((e => ({
                x: e.x,
                y: e.y
            }))),
            color: this.color,
            lineWidth: this.lineWidth,
            type: this.type
        })
    }
    clone() {
        return super.addMetadata(new NimaPen(this.points.map((e => ({
            x: e.x,
            y: e.y
        }))),this.color,this.lineWidth,this.type), this)
    }
    clone2() {
        return super.clone2(new NimaPen(this.points.map((e => ({
            x: e.x,
            y: e.y
        }))),this.color,this.lineWidth,this.type))
    }
}