/* eslint-disable */

import {level2} from "./nimaRectangle";

export class NimaCircle extends level2 {
    constructor(e, t, n, o, a, r) {
        super(),
            this.x = e,
            this.y = t,
            this.r = n,
            this.lineWidth = a,
            this.cmd = "circle",
        o && (this.stroke = o),
        r && (this.fill = r)
    }
    getAspectRatio(e) {
        return "preserved"
    }
    updateSize(e, t, n, o) {
        this.r = (Math.min(n, o) - this.lineWidth) / 2,
            this.updatePosition(e, t)
    }
    updatePosition(e, t) {
        var n = this.lineWidth / 2;
        this.x = e + this.r + n,
            this.y = t + this.r + n
    }
    getBoundingRect() {
        var e = this.lineWidth / 2;
        return {
            x: this.x - this.r - e,
            y: this.y - this.r - e,
            w: 2 * this.r + this.lineWidth,
            h: 2 * this.r + this.lineWidth
        }
    }
    hitTest(e, t) {
        if (!this.fill) {
            var n = Math.sqrt((e - this.x) * (e - this.x) + (t - this.y) * (t - this.y))
                , o = this.lineWidth / 2;
            return n < this.r + o && n > this.r - o
        }
        return this.contains(e, t)
    }
    contains(e, t) {
        return Math.sqrt((e - this.x) * (e - this.x) + (t - this.y) * (t - this.y)) < this.r
    }
    upgradeFromVer1() {
        var e, t;
        !this.fill && this.fillBrush && "s" === this.fillBrush.type ? e = this.color : !this.stroke && this.fillBrush && "e" === this.fillBrush.type && (t = this.color),
            this.stroke = t || this.stroke,
            this.fill = e || this.fill,
        this.fillBrush && (this.v1 = {
            type: this.fillBrush.type
        }),
            this.color = void 0,
            this.fillBrush = void 0
    }
    draw(e) {
        if (!(this.r < 0)) {
            if (e.beginPath(),
                this.fill) {
                var t = this.lineWidth ? this.lineWidth / 2 : 0;
                e.fillStyle = this.fill;
                var n = this.r - t;
                if (n <= 0)
                    return;
                e.arc(this.x, this.y, n, 0, 2 * Math.PI),
                    e.fill()
            }
            e.beginPath(),
            this.stroke && (e.lineWidth = this.lineWidth,
                e.strokeStyle = this.stroke,
                e.arc(this.x, this.y, this.r, 0, 2 * Math.PI),
                e.stroke())
        }
    }
    toJSON() {
        return super.toJSON({
            cmd: "circle",
            x: this.x,
            y: this.y,
            r: this.r,
            stroke: this.stroke,
            lineWidth: this.lineWidth,
            fill: this.fill
        })
    }
    clone() {
        return super.addMetadata(new NimaCircle(this.x,this.y,this.r,this.stroke,this.lineWidth,this.fill), this)
    }
    clone2() {
        return super.clone2(new NimaCircle(this.x,this.y,this.r,this.stroke,this.lineWidth,this.fill))
    }
}