import PropTypes from 'prop-types';
import { SUBTOOLBAR_ITEMS } from '../../helpers/Constant';
import DeleteItem from './items/Delete';
import LockItem from './items/Lock';
import DuplicateItem from './items/Duplicate';
import DownloadItem from './items/Download';
import ChangeShapeTypeItem from './items/ChangeShapeType';
import AlignmentItem from './items/Alignment';
import FontStyleItem from './items/FontStyle';
import HyperlinkItem from './items/Hyperlink';
import LineTypeItem from './items/LineType';
import ArrowTypeItem from './items/ArrowType';
import AddLineTextItem from './items/AddLineText';
import FontSizeItem from './items/FontSize';
import ColorPickerItem from './items/ColorPicker';
import MoreItem from './items/More';
import FiltersItem from './items/Filters';
import SeperatorItem from './items/Seperator';
import { ChangeShapeLayer } from './items/ChangeShapeLayer';

const SubToolbarItem = ({
    item,
    targetType,
    targetAttachmentsLength,
    isLocked,
    removeShape,
    onClick,
    onChange,
    onDisplay,
    handleHoverDeleteItem,
    isLoading,
    isDisabled,
    showMenu,
    shapeType,
    isShapeHasTextOrEditMode,
    toggleMenu,
    getTextboxAlignIcon,
    getActiveTextAlign,
    getActiveFontStyle,
    strokeWidth,
    strokeDashArray,
    lineType,
    changeLineType,
    changeThickness,
    toggleDashed,
    arrowLeft,
    arrowRight,
    textFontSize,
    stateColor,
    handleMouseOverColor,
    handleCloseColorPicker,
    setColor,
    maximumSize,
    isTargetLine,
    filters,
    isFilterApplied,
    recentColors,
    updateRecentColor,
    layerId
}) => {
    let component = null;

    if (typeof onDisplay === 'function' && onDisplay() !== true) {
        return null;
    }

    switch (item) {
        case SUBTOOLBAR_ITEMS.DELETE.id:
            component = (
                <DeleteItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    isDeleteBlock={targetType === 'frame'}
                    showDeleteOptions={!!(targetType === 'frame' && targetAttachmentsLength > 0)}
                    removeShape={removeShape}
                    handleHoverDeleteItem={handleHoverDeleteItem}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.LOCK.id:
            component = (
                <LockItem
                    isLocked={isLocked}
                    lockTarget={onClick}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.DUPLICATE.id:
            component = (
                <DuplicateItem
                    isDisabled={isDisabled}
                    cloneShape={onClick}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.DOWNLOAD.id:
            component = (
                <DownloadItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    isLoading={isLoading}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.CHANGE_SHAPE.id:
            component = (
                <ChangeShapeTypeItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    isLocked={isLocked}
                    isShapeHasTextOrEditMode={isShapeHasTextOrEditMode}
                    shapeType={shapeType}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.ALIGNMENT.id:
            component = (
                <AlignmentItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                    getTextboxAlignIcon={getTextboxAlignIcon}
                    getActiveTextAlign={getActiveTextAlign}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.FONT_STYLE.id:
            component = (
                <FontStyleItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                    getActiveFontStyle={getActiveFontStyle}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.HYPERLINK.id:
            component = (
                <HyperlinkItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.LINE_TYPE.id:
            component = (
                <LineTypeItem
                    isDisabled={isDisabled}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                    strokeWidth={strokeWidth}
                    strokeDashArray={strokeDashArray}
                    lineType={lineType}
                    changeLineType={changeLineType}
                    changeThickness={changeThickness}
                    toggleDashed={toggleDashed}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.ARROW_TYPE.id:
            component = (
                <ArrowTypeItem
                    isDisabled={isDisabled}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                    onClick={onClick}
                    arrowLeft={arrowLeft}
                    arrowRight={arrowRight}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.ADD_LINE_TEXT.id:
            component = (
                <AddLineTextItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.LINE_FONT_SIZE.id:
            component = (
                <FontSizeItem
                    isDisabled={isDisabled}
                    onChange={onChange}
                    textFontSize={textFontSize}
                    maximumSize={maximumSize}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.TEXT_COLOR.id:
        case SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id:
        case SUBTOOLBAR_ITEMS.OUTER_COLOR.id:
        case SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id:
        case SUBTOOLBAR_ITEMS.INNER_COLOR.id:
            component = (
                <ColorPickerItem
                    itemType={item}
                    isDisabled={isDisabled}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                    stateColor={stateColor}
                    handleMouseOverColor={handleMouseOverColor}
                    handleCloseColorPicker={handleCloseColorPicker}
                    setColor={setColor}
                    targetType={targetType}
                    isTargetLine={isTargetLine}
                    recentColors={recentColors}
                    updateRecentColor={updateRecentColor}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.FONT_SIZE.id:
            component = (
                <FontSizeItem
                    isDisabled={isDisabled}
                    onChange={onChange}
                    textFontSize={textFontSize}
                    maximumSize={maximumSize}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.FILTERS.id:
            component = (
                <FiltersItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                    showMenu={showMenu}
                    toggleMenu={toggleMenu}
                    filters={filters}
                    isFilterApplied={isFilterApplied}
                />
            )
            break;
        case SUBTOOLBAR_ITEMS.CHANGE_LAYER.id:
            component = <ChangeShapeLayer isDisabled={isDisabled} showMenu={showMenu} toggleMenu={toggleMenu} layerId={layerId} onChange={onChange}/>;
            break;
        case SUBTOOLBAR_ITEMS.SEPERATOR.id:
            component = (
                <SeperatorItem />
            )
            break;
        case SUBTOOLBAR_ITEMS.MORE.id:
            component = (
                <MoreItem
                    isDisabled={isDisabled}
                    onClick={onClick}
                />
            )
            break;
    }

    return component;
};

SubToolbarItem.propTypes = {
    item: PropTypes.oneOf([
        SUBTOOLBAR_ITEMS.DELETE.id,
        SUBTOOLBAR_ITEMS.LOCK.id,
        SUBTOOLBAR_ITEMS.DUPLICATE.id,
        SUBTOOLBAR_ITEMS.DOWNLOAD.id,
        SUBTOOLBAR_ITEMS.CHANGE_SHAPE.id,
        SUBTOOLBAR_ITEMS.ALIGNMENT.id,
        SUBTOOLBAR_ITEMS.FONT_STYLE.id,
        SUBTOOLBAR_ITEMS.HYPERLINK.id,
        SUBTOOLBAR_ITEMS.FONT_SIZE.id,
        SUBTOOLBAR_ITEMS.OUTER_COLOR.id,
        SUBTOOLBAR_ITEMS.HIGHLIGHT_COLOR.id,
        SUBTOOLBAR_ITEMS.INNER_COLOR.id,
        SUBTOOLBAR_ITEMS.LINE_TYPE.id,
        SUBTOOLBAR_ITEMS.ARROW_TYPE.id,
        SUBTOOLBAR_ITEMS.ADD_LINE_TEXT.id,
        SUBTOOLBAR_ITEMS.LINE_FONT_SIZE.id,
        SUBTOOLBAR_ITEMS.TEXT_COLOR.id,
        SUBTOOLBAR_ITEMS.LINE_TEXT_COLOR.id,
        SUBTOOLBAR_ITEMS.FILTERS.id,
        SUBTOOLBAR_ITEMS.SEPERATOR.id,
        SUBTOOLBAR_ITEMS.MORE.id,
        SUBTOOLBAR_ITEMS.CHANGE_LAYER.id
    ]).isRequired,
    isLocked: PropTypes.bool,
    targetType: PropTypes.string,
    targetAttachmentsLength: PropTypes.number,
    removeShape: PropTypes.func,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onDisplay: PropTypes.func.isRequired,
    handleHoverDeleteItem: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    showMenu: PropTypes.bool,
    shapeType: PropTypes.string,
    isShapeHasTextOrEditMode: PropTypes.bool,
    toggleMenu: PropTypes.func,
    getTextboxAlignIcon: PropTypes.func,
    getActiveTextAlign: PropTypes.func,
    getActiveFontStyle: PropTypes.func,
    strokeWidth: PropTypes.number,
    strokeDashArray: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number
    ]),
    lineType: PropTypes.oneOf([
        'straight',
        'curved',
        'stepper'
    ]),
    changeLineType: PropTypes.func,
    changeThickness: PropTypes.func,
    toggleDashed: PropTypes.func,
    arrowRight: PropTypes.bool,
    arrowLeft: PropTypes.bool,
    textFontSize: PropTypes.number,
    stateColor: PropTypes.string,
    handleMouseOverColor: PropTypes.func,
    handleCloseColorPicker: PropTypes.func,
    setColor: PropTypes.func,
    maximumSize: PropTypes.number,
    isTargetLine: PropTypes.bool,
    filters: PropTypes.object,
    isFilterApplied: PropTypes.bool,
    recentColors: PropTypes.arrayOf(PropTypes.string),
    updateRecentColor: PropTypes.func,
    layerId: PropTypes.string,
}

export default SubToolbarItem;