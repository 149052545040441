/* eslint-disable */

class RectHelper {
    static rotatePoint(e, t, n, o, a) {
        var r = Math.cos(a)
            , i = Math.sin(a);
        return {
            x: r * (e - n) + i * (t - o) + n,
            y: r * (t - o) - i * (e - n) + o
        }
    }
    static rotateRect(e, t, n, a) {
        var r = [{
            x: e.x,
            y: e.y
        }, {
            x: e.x + e.w,
            y: e.y
        }, {
            x: e.x + e.w,
            y: e.y + e.h
        }, {
            x: e.x,
            y: e.y + e.h
        }].map((e => o.rotatePoint(e.x, e.y, t, n, a)))
            , i = Math.min(...r.map((e => e.x)))
            , s = Math.min(...r.map((e => e.y)));
        return {
            x: i,
            y: s,
            w: Math.max(...r.map((e => e.x))) - i,
            h: Math.max(...r.map((e => e.y))) - s
        }
    }
}

export class level1 {
    constructor() {
        this.id = null;
            this.timestamp = 0,
            this.uid = null;
    }
    toJSON(e) {
        return Object.assign({
            id: this.id,
            timestamp: this.timestamp,
            uid: this.uid
        }, e)
    }
    static create(e) {
        return {
            id: (0,
                IdHelper)(),
            timestamp: 0,
            cmd: e,
            uid: IdHelper.getUid()
        }
    }
    static getDate() {
        return "pending"
    }
}

export class level2 extends level1 {
    constructor() {
        super()
    }

    get rotatable() {
        return !1
    }

    startRotate(e) {
        if (this.rotation) {
            var t = this.getCenter();
            e.translate(t.x, t.y),
                e.rotate(this.rotation),
                e.translate(-t.x, -t.y)
        }
    }

    endRotate(e) {
        if (this.rotation) {
            var t = this.getCenter();
            e.translate(t.x, t.y),
                e.rotate(-this.rotation),
                e.translate(-t.x, -t.y)
        }
    }

    rotatePoint(e, t) {
        var n = this.getCenter();
        return RectHelper.rotatePoint(e, t, n.x, n.y, this.rotation)
    }

    rotateRect(e) {
        var t = {
            x: e.w / 2 + e.x,
            y: e.h / 2 + e.y
        };
        return RectHelper.rotateRect(e, t.x, t.y, this.rotation)
    }

    getRotatedPoints(e, t) {
        if (this.rotation) {
            var n = this.rotatePoint(e, t);
            return [n.x, n.y]
        }
        return [e, t]
    }

    getCenter() {
        var e = this.getBoundingRect();
        return {
            x: e.w / 2 + e.x,
            y: e.h / 2 + e.y
        }
    }

    isGhost() {
        return !1
    }

    isFixedPosition() {
        return !1
    }

    getAspectRatio(e) {
        return "boolean" == typeof e && e ? "free" : "preserved"
    }

    getBoundingRect(e) {
        return {
            x: 0,
            y: 0,
            w: 0,
            h: 0
        }
    }

    updatePosition(e, t) {
        this.x = e,
            this.y = t
    }

    getUpdatedContent(e, t) {
    }

    updateSize(e, t, n, o) {
    }

    getArea() {
        var e = this.getBoundingRect();
        return e.w * e.h
    }

    contains(e, t) {
        return !1
    }

    hitTest(e, t) {
        return this.contains(e, t)
    }

    intersects(e) {
        var t = this.getBoundingRect();
        return t.x < e.x + e.w && t.x + t.w > e.x && t.y < e.y + e.h && t.y + t.h > e.y
    }

    getText() {
    }

    setStyle(e) {
        e.lineJoin = "round",
            e.lineCap = "round",
            e.strokeStyle = this.color,
            e.lineWidth = this.lineWidth
    }

    toJSON(e) {
        return this.metadata && Object.assign(e, {
            metadata: JSON.parse(JSON.stringify(this.metadata))
        }),
        this.tags && Object.assign(e, {
            tags: JSON.parse(JSON.stringify(this.tags))
        }),
            super.toJSON(e)
    }

    clone() {
        return null
    }

    addMetadata(e, t) {
        return t.metadata && (e.metadata = JSON.parse(JSON.stringify(t.metadata))),
        t.tags && (e.tags = JSON.parse(JSON.stringify(t.tags))),
        t.rotation && (e.rotation = t.rotation),
            e
    }

    drawVisualFeedback(e) {
        e.lineWidth = 1,
            e.strokeStyle = "#0282fd";
        var t = this.getBoundingRect();
        e.strokeRect(t.x, t.y, t.w, t.h)
    }

    clone2(e) {
        return e ? (e.id = this.id,
        this.metadata && (e.metadata = JSON.parse(JSON.stringify(this.metadata))),
        this.tags && (e.tags = JSON.parse(JSON.stringify(this.tags))),
        this.rotation && (e.rotation = this.rotation),
        this._cached && (e._cached = JSON.parse(JSON.stringify(this._cached))),
            e) : null
    }
}

export class NimaRectangle extends level2 {
    constructor(e, t, n, o, a, r, i) {
        super(),
            this.x = e,
            this.y = t,
            this.w = n,
            this.h = o,
            this.lineWidth = r,
            this.cmd = "rectangle",
        a && (this.stroke = a),
        i && (this.fill = i)
    }
    get rotatable() {
        return !0
    }
    getAspectRatio(e) {
        return "boolean" == typeof e && e ? "preserved" : "free"
    }
    getThickness() {
        return this.lineWidth / 2
    }
    updateSize(e, t, n, o) {
        this.updatePosition(e, t),
            this.w = n - this.lineWidth,
            this.h = o - this.lineWidth
    }
    updatePosition(e, t) {
        this.x = e + this.getThickness(),
            this.y = t + this.getThickness()
    }
    getBoundingRect(e) {
        var t = e && e.skipTransform
            , n = this.getThickness()
            , o = {
            x: this.x - n,
            y: this.y - n,
            w: this.w + this.lineWidth,
            h: this.h + this.lineWidth
        };
        return !t && this.rotation ? this.rotateRect(o) : o
    }
    contains(e, t) {
        return [e,t] = this.getRotatedPoints(e, t),
        e >= this.x && e <= this.x + this.w && t + 3 >= this.y && t + 3 <= this.y + this.h
    }
    hitTest(e, t) {
        if (!this.fill) {
            var n = this.getThickness();
            return [e,t] = this.getRotatedPoints(e, t),
            (e >= this.x - n && e <= this.x + n || e >= this.x + this.w - n && e <= this.x + this.w + n) && t >= this.y - n && t <= this.y + this.h + n || (t >= this.y - n && t <= this.y + n || t >= this.y + this.h - n && t <= this.y + this.h + n) && e >= this.x - n && e <= this.x + this.w + n
        }
        return this.contains(e, t)
    }
    upgradeFromVer1() {
        var e, t;
        !this.fill && this.fillBrush && "s" === this.fillBrush.type ? e = this.color : !this.stroke && this.fillBrush && "e" === this.fillBrush.type && (t = this.color),
            this.stroke = t || this.stroke,
            this.fill = e || this.fill,
        this.fillBrush && (this.v1 = {
            type: this.fillBrush.type
        }),
            this.color = void 0,
            this.fillBrush = void 0
    }
    draw(e) {
        if (this.startRotate(e),
            this.fill) {
            var t = this.lineWidth ? this.lineWidth / 2 : 0;
            e.fillStyle = this.fill,
                e.fillRect(this.x + t, this.y + t, this.w - this.lineWidth || 0, this.h - this.lineWidth || 0)
        }
        this.stroke && (e.lineWidth = this.lineWidth,
            e.strokeStyle = this.stroke,
            e.strokeRect(this.x, this.y, this.w, this.h)),
            this.endRotate(e)
    }
    toJSON() {
        return super.toJSON({
            cmd: "rectangle",
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            stroke: this.stroke,
            lineWidth: this.lineWidth,
            fill: this.fill
        })
    }
    clone() {
        return super.addMetadata(new NimaRectangle(this.x,this.y,this.w,this.h,this.stroke,this.lineWidth,this.fill), this)
    }
    clone2() {
        return super.clone2(new NimaRectangle(this.x,this.y,this.w,this.h,this.stroke,this.lineWidth,this.fill))
    }
}