import {compressData, decompressData} from '../../helpers/OptimizationUtils';
import { v4 as uuidv4 } from 'uuid';
import {toast} from 'react-toastify';

export class CanvasLockManager {
    constructor() {
        this.currentlyEditingShapes = new Set();
        this.toastId = null;
        this.timestamps = new Map();  // <processId, timestamp>
    }
    
    setSocket(socket) {
        this.socket = socket;
    }
    
    lockObjects(objects, lockedBy) {
        for (const object of objects) {
            if (!object) {
                continue
            }
            object.onShapeChanged();
            object.collabLocked = true;
            object.collabLockedBy = lockedBy;
        }
    }
    
    lockObjectsByUuid(canvas, uuids, lockedBy) {
        const canvasObjects = canvas.getObjects();
        const objects = uuids.map(uuid => {
            const object = canvas.objectsMap.get(uuid)
            if (object) {
                return object
            }
            return canvasObjects.find(obj => obj?.uuid === uuid)
        })?.filter(o=>o.visible);
        this.lockObjects(objects, lockedBy);
    }

    unlockObjects(objects) {
        for (const object of objects) {
            if (!object) {
                continue
            }
            object.onShapeChanged();
            object.collabLocked = false;
            object.collabLockedBy = null;
        }
    }
    
    unlockObjectsByUuid(canvas, uuids) {
        const canvasObjects = canvas.getObjects();
        const objects = uuids.map(uuid => {
            const object = canvas.objectsMap.get(uuid)
            if (object) {
                return object
            }
            
            return canvasObjects.find(obj => obj?.uuid === uuid)
        });
        this.unlockObjects(objects);
    }
    
    startEditing(shapes, pageId, callback, options = {}) {
        const processId = options?.processId || uuidv4();
        const shapeUuids = []

        for (const shape of shapes) {
            if (!shape.uuid) {
                continue
            }
            if (shape.type === 'lasso') {
                continue
            }
            this.currentlyEditingShapes.add(shape.uuid);
            shapeUuids.push(shape.uuid);
        }
        if (!shapeUuids.length) {
            console.error('No shapes to edit')
            return {
                shapeUuids,
                processId
            }
        }

        const sendingData = {
            uuid: processId,
            status: 'freeze',
            shapes: shapeUuids,
            pageId,
            ...this.getTimestamp(processId, true)
        }
        const compressed = compressData(sendingData);
        this.socket.emit('collabLock', compressed, (data) => {
            const failedShapes = []
            const successShapes = []
            const emitData = decompressData(data.emitData)

            for (const emitShape of emitData.shapes) {
                if (emitShape.success) {
                    successShapes.push(emitShape.shapeUUID)
                } else {
                    failedShapes.push(emitShape.shapeUUID)
                }
            }

            if (!options.avoidShowingToast && failedShapes.length > 0) {
                this.showToast()
            }

            callback({
                successShapes,
                failedShapes,
                processId
            })
        })
        
        return {
            shapeUuids,
            processId
        };
    }
    
    stopEditing(shapes, pageId, processId, options = {}) {
        const shapeUuids = []
        for (const shape of shapes) {
            this.currentlyEditingShapes.delete(shape.uuid);
            shapeUuids.push(shape.uuid);
        }

        if (!options.avoidEmitting) {
            const sendingData = {
                uuid: processId,
                status: 'unfreeze',
                shapes: shapeUuids,
                pageId,
                ...this.getTimestamp(processId)
            }
            const compressed = compressData(sendingData)
            this.socket.emit('collabLock', compressed)
        }
    }
    
    showToast() {
        if (!toast.isActive(this.toastId)) {
            this.toastId = toast.warn('Few elements within the selection area are currently being edited.', {
                className: 'wb_toast'
            })
        }
    }
    
    getTimestamp(processId, isFreezeEvent) {
        let currentTime = Date.now()
        if (isFreezeEvent && processId) {
            this.timestamps.set(processId, currentTime)
        }

        // handle for unfreeze event
        if (!isFreezeEvent) {
            // check if the same timestamp was generated for freeze
            // if it is, we need to generate new timestamp
            const timestampInFreeze = this.timestamps.get(processId)
            if (timestampInFreeze && timestampInFreeze === currentTime) {
                currentTime += 5
            }
        }

        return {
            timestamp: currentTime,
            hexTimestamp: `0x${currentTime.toString(16)}`
        } 
    }
}