export class NimaBoardImportAnalyzer {
    #_totalShapeCount = 0;
    #_importedShapeCount = 0;
    #_totalImageCount = 0;
    #_importedImageCount = 0;
    #_totalCommentCount = 0;
    #_importedCommentCount = 0;
    #_totalMemberCount = 0;
    #_importedMemberCount = 0;
    constructor() {
    }
    
    addImportedShapes(addedShapeCount) {
        this.#_importedShapeCount += addedShapeCount;
    }

    addImportedImages(addedImageCount) {
        this.#_importedImageCount += addedImageCount;
    }
    
    addImportedComments(addedCommentCount) {
        this.#_importedCommentCount += addedCommentCount;
    }
    
    addImportedMembers(addedMembersCount) {
        this.#_importedMemberCount += addedMembersCount;
    }
    
    #getPercentage(total, count) {
        if ((total === 0 && count > 0) || (total === 0 && count === 0)) {
            return 100
        }
        return Math.floor((count * 100) / total)
    }
    
    getAnalysisReport() {
        return {
            numbers: {
                totalShapeCount: this.#_totalShapeCount,
                importedShapeCount: this.#_importedShapeCount,
                totalImageCount: this.#_totalImageCount,
                importedImageCount: this.#_importedImageCount,
                totalCommentCount: this.#_totalCommentCount,
                importedCommentCount: this.#_importedCommentCount,
                totalMemberCount: this.#_totalMemberCount,
                importedMemberCount: this.#_importedMemberCount,
            },
            shapeImportPercentage: this.#getPercentage(this.#_totalShapeCount, this.#_importedShapeCount),
            imageImportPercentage: this.#getPercentage(this.#_totalImageCount, this.#_importedImageCount),
            commentImportPercentage: this.#getPercentage(this.#_totalCommentCount, this.#_importedCommentCount),
            memberImportPercentage: this.#getPercentage(this.#_totalMemberCount, this.#_importedMemberCount)
        }
    }
    
    set totalShapeCount(val) {
        this.#_totalShapeCount = val;
    }

    set totalImageCount(val) {
        this.#_totalImageCount = val;
    }

    set totalCommentCount(val) {
        this.#_totalCommentCount = val;
    }
    
    set totalMemberCount(val) {
        this.#_totalMemberCount = val;
    }
}