import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { EMITTER_TYPES } from '../../../helpers/Constant';
import eventEmitter from '../../../helpers/EventEmitter';

import { fitToScreen as fitToScreenHelper } from '../../../hooks/PanZoom';
import {fitToScreenAssistance} from '../../../helpers/FitToScreenAssistance';

const FitToScreen = ({
    canvas
}) => {
    const fitToScreenAndSetToolbar = useCallback((activatedWithClick = false) => {
        if (canvas) {
            fitToScreenAssistance.initialize(canvas);
            if (activatedWithClick) {
                canvas.fire('stop-movement-of-object');
            }
            fitToScreenHelper(canvas, fitToScreenAssistance, {includeVisibleOnly:true});
            canvas.fire('board:zoom');
        }
    }, [canvas]);

    useEffect(() => {
        eventEmitter.on(EMITTER_TYPES.FIT_TO_SCREEN, fitToScreenAndSetToolbar);

        return () => {
            eventEmitter.off(EMITTER_TYPES.FIT_TO_SCREEN, fitToScreenAndSetToolbar);
        }
    }, [fitToScreenAndSetToolbar]);

    useEffect(() => {
        // We need to fit to screen in default after the canvas initialized
        if (canvas.isCanvasInitalized) {
            fitToScreenAndSetToolbar();
        }
    }, [canvas]);


    return (
        <div
            className="fitToScreen bottomBarButton"
            data-tutorial="fit-to-screen"
            onClick={() => fitToScreenAndSetToolbar(true)}
            title="Fit To Screen"
        >
            <em className="icon-canvas-redesign-fit-to-screen" />
            <span className="tooltiptext">Fit To Screen</span>
        </div>
    )
};

FitToScreen.propTypes = {
    canvas: PropTypes.object
}

export default FitToScreen;
