/* eslint-disable */

import {level2} from "./nimaRectangle";

export class NimaImage extends level2 {
    constructor(e, t, n, o, a) {
        super(),
            this.x = e,
            this.y = t,
            this.w = n,
            this.h = o,
        a && (a.match(/^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/i) ? this.resource = a : this.url = a),
            this.cmd = "image"
    }
    updateSize(e, t, n, o) {
        this.w,
            this.h;
        this.w = n,
            this.h = o,
            this.updatePosition(e, t)
    }
    contains(e, t) {
        return e >= this.x && e <= this.x + this.w && t + 3 >= this.y && t + 3 <= this.y + this.h
    }
    getBoundingRect() {
        return {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h
        }
    }
    getUrl(e) {
        var t = this.url;
        if (t || (t = "/api/assets/".concat(this.resource, "/").concat(e),
        this.metadata && this.metadata.wtapp_doc && (t += "?Page=" + this.metadata.wtapp_doc.page.current)),
            t.startsWith("/api/media/")) {
            var n = t.replace("/api/media/", "");
            t = "/api/assets/".concat(n, "/").concat(e)
        }
        return t
    }
    _getPreCache(e) {
        var t = e.getImageStatus(this)
            , n = e.get(this.getUrl(t));
        if (n || (t = "preview",
            n = e.get(this.getUrl(t))),
            n)
            return {
                type: t,
                cache: n
            }
    }
    draw(e) {
        var t = this
            , n = new a.A
            , o = "preview";
        if (this.renderType) {
            o = n.getImageStatus(this);
            var r, i = this.getUrl(o), s = n.get(i);
            if (s ? this.renderType = o : (s = n.get(this.getUrl(this.renderType)),
            n.isInQueue(i) || this._cacheImage(n, i, o)),
                s)
                "application/pdf" === (null === (r = this.metadata) || void 0 === r || null === (r = r.wtapp_doc) || void 0 === r ? void 0 : r.type) && (e.fillStyle = "#FFF",
                    e.fillRect(t.x, t.y, t.w, t.h)),
                    e.drawImage(s, t.x, t.y, t.w, t.h);
            else
                this._drawPlaceholder(e)
        } else {
            var l = this.getUrl(o)
                , c = this._getPreCache(n);
            c ? (this.renderType = c.type,
                e.drawImage(c.cache, t.x, t.y, t.w, t.h)) : (this._drawPlaceholder(e),
            n.isInQueue(l) || this._cacheImage(n, l, o))
        }
    }
    _cacheImage(e, t, n) {
        e.loadImage(t).then((e => {
                this.renderType = n,
                    r.A.emit("redraw")
            }
        )).catch((e => {
                "failed to fetch image" === e && i.A.error("load-image-error", "failed to fetch ".concat(this.id, ", resource: ").concat(this.resource))
            }
        ))
    }
    _drawPlaceholder(e) {
        e.fillStyle = "#F5F2F0",
            e.strokeStyle = "#adadad",
            e.lineWidth = 1,
            e.setLineDash([10, 10]),
            e.fillRect(this.x, this.y, this.w, this.h),
            e.strokeRect(this.x, this.y, this.w, this.h),
            e.beginPath(),
            e.moveTo(this.x, this.y),
            e.lineTo(this.x + this.w, this.y + this.h),
            e.closePath(),
            e.stroke(),
            e.beginPath(),
            e.moveTo(this.x + this.w, this.y),
            e.lineTo(this.x, this.y + this.h),
            e.closePath(),
            e.stroke(),
            e.setLineDash([])
    }
    toJSON() {
        return super.toJSON({
            cmd: "image",
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            url: this.url,
            resource: this.resource
        })
    }
    clone() {
        var e = new NimaImage(this.x,this.y,this.w,this.h);
        return e.url = this.url,
            e.resource = this.resource,
            e.renderType = this.renderType,
            super.addMetadata(e, this)
    }
    clone2() {
        var e = new NimaImage(this.x,this.y,this.w,this.h);
        return this.url && (e.url = this.url),
        this.resource && (e.resource = this.resource),
        this.renderType && (e.renderType = this.renderType),
            super.clone2(e)
    }
}