/* eslint-disable */

import {level2} from "./nimaRectangle";

const VS = {
    colors: [{
        name: "1",
        color: "#2141FA"
    }, {
        name: "2",
        color: "#FF8C00"
    }, {
        name: "3",
        color: "#DC3545"
    }, {
        name: "4",
        color: "#0C695D"
    }, {
        name: "5",
        color: "#845EC2"
    }, {
        name: "6",
        color: "#101010"
    }],
    w: 32,
    h: 32
}

export class NimaComment extends level2 {
    constructor(e, t, n, o, a) {
        super(),
            this.cmd = "comment",
            this.x = e,
            this.y = t,
            this.threadId = n,
            this.color = VS.colors[5].name,
        o && (this.color = o),
            this.resolved = !1,
        a && (this.resolved = a),
            this.unreadCount = 0
    }
    updateSize(e, t, n, o) {}
    contains(e, t) {
        var n = this.boardContext ? this.boardContext.scale : 1;
        return e >= this.x && e <= this.x + VS.w / n && t + 3 >= this.y && t + 3 <= this.y + VS.h / n
    }
    getBoundingRect() {
        var e = this.boardContext ? this.boardContext.scale : 1;
        return {
            x: this.x,
            y: this.y,
            w: VS.w / e,
            h: VS.h / e
        }
    }
    isGhost() {
        return !0
    }
    isFixedPosition() {
        return !0
    }
    draw(e) {
        var t = this.boardContext ? this.boardContext.scale : 1
            , n = (n, o) => {
                e.beginPath(),
                    e.arc(n, o, 1, 0, 2 / t * Math.PI),
                    e.closePath(),
                    e.stroke()
            }
        ;
        e.save(),
            e.translate(this.x, this.y),
            e.scale(VS.w / t / 40, VS.h / t / 40);
        var o = VS.colors.find((e => e.name === this.color));
        if (e.fillStyle = "#FFFFFF",
            e.beginPath(),
            e.fill(SS),
            e.fillStyle = o.color,
            e.strokeStyle = o.color,
            e.lineWidth = 1,
            n(13.33069, 16.07594),
            n(26.66931, 16.07594),
            n(20, 16.07594),
            e.beginPath(),
            e.fill(AS),
            e.restore(),
        this.unreadCount > 0) {
            e.font = 12 / t + "px arial",
                e.textBaseline = "middle";
            var a = e.measureText(this.unreadCount).width
                , r = 7 + 2 * this.unreadCount.toString().length;
            e.fillStyle = o.color,
                e.beginPath(),
                e.arc(this.x + VS.w / t, this.y, r / t, 0, 2 * Math.PI),
                e.fill(),
                e.fillStyle = "#FFFFFF",
                e.fillText(this.unreadCount, this.x + VS.w / t - a / 2, this.y + 1 / t)
        }
    }
    toJSON() {
        return super.toJSON({
            cmd: "comment",
            x: this.x,
            y: this.y,
            threadId: this.threadId,
            color: this.color,
            resolved: this.resolved
        })
    }
    clone() {
        var e = new NimaComment(this.x,this.y,this.threadId,this.color,this.resolved);
        return super.addMetadata(e, this)
    }
    clone2() {
        var e = new NimaComment(this.x,this.y,this.threadId,this.color,this.resolved);
        return super.clone2(e)
    }
}