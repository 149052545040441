import PropTypes from 'prop-types';

export default function IconPlus({
    width,
    height,
    color,
    styleOverride
}) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='12'
            height='12'
            fill='none'
            viewBox='0 0 12 12'
            style={{width: `${width}px`, height: `${height}px`, ...styleOverride}}
        >
            <path
                stroke={color ? color : '#3C3E49'}
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M1.333 6h9.334M6 1.334v9.333'
            />
        </svg>
    )
}


IconPlus.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    styleOverride: PropTypes.object
}